import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
export type TrendProps = {
  currentStat: number;
  previousStat: number;
};

export default function Trend({ currentStat, previousStat }: TrendProps) {
  let decreaseValue = currentStat - previousStat;

  // if (currentStat > previousStat) {
  //   decreaseValue = previousStat - currentStat;
  // }
  let percentage = (decreaseValue / previousStat) * 100;
  if (currentStat > previousStat) {
    // Increase
    return (
      <>
        {currentStat}&nbsp;
        <FontAwesomeIcon icon={faArrowUp} fixedWidth className="text-success" />
        &nbsp;
        <small className="text-muted">
          {" "}
          + {Math.abs(percentage).toFixed()} %
        </small>
      </>
    );
  } else if (currentStat < previousStat) {
    // Decrease
    return (
      <>
        {currentStat}&nbsp;
        <FontAwesomeIcon
          icon={faArrowDown}
          fixedWidth
          className="text-danger"
        />
        &nbsp;<small className="text-muted"> - {percentage.toFixed()} %</small>
      </>
    );
  } else {
    return (
      <>
        {/* No Change */}
        {currentStat}&nbsp;
        <FontAwesomeIcon icon={faMinus} fixedWidth className="text-secondary" />
      </>
    );
  }
}
