import { FunctionComponent } from "react";
import ActiveUsers from "../charts/ActiveUsers";
import NestQueueLength from "../charts/NestingQueueLength";
import NestPerHour from "../charts/NestPerHour";
import IssuesContainer from "../charts/IssuesContainer";
import FilterBar from "../charts/FilterBar";

const DashBoard: FunctionComponent = () => {
  return (
    <section className="container-fluid p-3 h-100">
      <div className="row gx-3 h-100 ">
        <div className="col d-flex flex-column">
          {/* Filter BaR */}
          <FilterBar />
          <div className="row gx-3 h-50 mb-2">
            {/* Active Users */}
            <div className="col-lg-6">
              <ActiveUsers />
            </div>

            <div className="col-lg-6">
              <NestQueueLength />
            </div>
          </div>
          <div className="row gx-3 h-50 pt-2">
            <div className="col-lg-6">
              <NestPerHour />
            </div>

            <div className="col-lg-6">
              <IssuesContainer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DashBoard;
