import { FunctionComponent, ReactNode } from "react";

import TypeBackgroundLive from "../img/TypeBackgroundLive.svg";

const TypeBackground: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const background =
    process.env.REACT_APP_BUILD_TYPE === "production"
      ? `url(${TypeBackgroundLive})`
      : "";

  return (
    <div
      className="h-100 d-flex flex-column"
      style={{
        backgroundImage: background,
      }}
    >
      {children}
    </div>
  );
};

export default TypeBackground;
