import {
  clearMaintenanceMessage,
  fetchMaintenance,
  setMaintenanceMessage,
} from "./maintenanceSlice";
import {
  getClearMaintenanceMessageLoading,
  getMaintenanceLoading,
  getMaintenanceState,
} from "./maintenance";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetMaintenanceMessage } from "../../service/api.dtos";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const MaintenanceMessage = () => {
  const dispatch = useAppDispatch();
  const MaintenanceState = useAppSelector(getMaintenanceState);
  const maintenanceLoading = useAppSelector(getMaintenanceLoading);
  const clearMaintenanceMessageLoading = useAppSelector(
    getClearMaintenanceMessageLoading
  );

  const [localMaintenanceMessage, setLocalMaintenanceMessage] =
    useState<string>("");
  const [localMaintenanceExpires, setLocalMaintenanceExpires] =
    useState<string>("");

  useEffect(() => {
    const expiresDateFromApi = MaintenanceState?.maintenanceMode?.ttl
      ? //Local string adds an hour(iso sting is sent which loses an hour) - Swedish (Sweden) format for the date picker
        new Date(MaintenanceState?.maintenanceMode?.ttl).toLocaleString("sv-SE")
      : "";

    setLocalMaintenanceMessage(
      MaintenanceState?.maintenanceMode?.currentMessage
    );
    setLocalMaintenanceExpires(expiresDateFromApi);
  }, [
    MaintenanceState?.maintenanceMode?.currentMessage,
    MaintenanceState?.maintenanceMode?.ttl,
  ]);

  useEffect(() => {
    dispatch(fetchMaintenance());
  }, [dispatch]);

  function handleSaveMaintenanceMessage(): void {
    const args = new SetMaintenanceMessage();

    args.message = localMaintenanceMessage;
    args.expires = new Date(localMaintenanceExpires).toISOString();

    dispatch(setMaintenanceMessage(args))
      .unwrap()
      .then(() => {
        dispatch(fetchMaintenance());
      });
  }

  function handleClearMaintenanceMessage(): void {
    setLocalMaintenanceMessage("");
    setLocalMaintenanceExpires("");
    dispatch(clearMaintenanceMessage())
      .catch((err: Error) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            text: err.message ?? "Error clearing maintenance message",
            severity: "danger",
          })
        );
      })
      .finally(() => dispatch(fetchMaintenance()));
  }

  return (
    /* Maintenance message */
    <div className="mb-3">
      <label className="form-label">Maintenance Message</label>
      <div className="input-group input-group-sm">
        <span className="input-group-text bg-secondary">Message</span>
        <textarea
          rows={1}
          className="form-control w-50"
          value={localMaintenanceMessage}
          onChange={(e) => {
            setLocalMaintenanceMessage(e.currentTarget.value);
          }}
        />
        {/* Expires */}

        <span className="input-group-text bg-secondary">Expires</span>

        <input
          style={{ minWidth: "175px" }}
          type="datetime-local"
          className="form-control"
          value={localMaintenanceExpires}
          onChange={(e) => {
            setLocalMaintenanceExpires(e.currentTarget.value);
          }}
        />

        {/* Revert to Default */}
        <button
          className="btn btn-secondary border bg-dark"
          onClick={handleClearMaintenanceMessage}
        >
          {/* <FontAwesomeIcon icon={faTimes} fixedWidth /> */}
          Default
        </button>
        {/* Save */}
        <button
          className="btn btn-outline-primary border border-primary border bg-dark"
          onClick={handleSaveMaintenanceMessage}
          disabled={
            maintenanceLoading === "pending" ||
            clearMaintenanceMessageLoading === "pending" ||
            !localMaintenanceMessage ||
            !localMaintenanceExpires
          }
        >
          {maintenanceLoading === "pending" ? (
            <FontAwesomeIcon
              icon={faCircleNotch}
              fixedWidth
              spin
              className="text-primary"
            />
          ) : (
            <>Save</>
          )}
        </button>
      </div>
    </div>
  );
};

export default MaintenanceMessage;
