import MaintenanceEmailLogs from "./MaintenanceEmailLogs";
import MaintenanceEmailMessage from "./MaintenanceEmailMessage";
import MaintenanceEmailMessageModal from "./MaintenanceEmailMessageModal";
import MaintenanceLogs from "./MaintenanceLogs";
import MaintenanceMessage from "./MaintenanceMessage";
import MaintenanceModeSwitch from "./MaintenanceModeSwitch";

//import MaintenanceMode from "./MaintenanceMode";

const MaintenanceScreen = () => {
  return (
    <>
      {" "}
      <section className="container-lg py-3 h-100">
        <div className="card text-body bg-dark h-100">
          <div className="px-3 pt-3 d-flex align-items-center">
            <h4 className="text-nowrap text-capitalize">Maintenance</h4>
            {/* Bar spacer */}
            <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
              .
            </div>
            {/* Maintenance Mode */}
            <MaintenanceModeSwitch />
          </div>

          <div className="h-100 d-flex position-relative m-3">
            <div className="border rounded d-flex flex-column theme-overflow">
              <div className=" d-flex flex-column flex-grow-1">
                <div className="p-3">
                  {/* Maintenance Message */}
                  <MaintenanceMessage />
                </div>
                <hr></hr>
                {/* Maintenance Email Message */}
                <div className="p-3">
                  <MaintenanceEmailMessage />
                </div>

                <div className="d-flex flex-column flex-fill">
                  {/* Tabs */}
                  <div className="card-header border-top border-bottom">
                    <ul className="nav nav-tabs card-header-tabs">
                      <li className="nav-item">
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#MaintenanceEmailLogs-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="MaintenanceEmailLogs-tab-pane"
                          aria-selected="true"
                        >
                          <span className="fw-bold text-white">Email Logs</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className="nav-link  "
                          data-bs-toggle="tab"
                          data-bs-target="#MaintenanceLogs-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="MaintenanceLogs-tab-pane"
                          aria-selected="false"
                        >
                          <span className="fw-bold text-white">
                            Maintenance Logs
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  {/*  */}

                  {/* Email Logs */}
                  {/* Tab Panes */}
                  <div
                    className="tab-content flex-grow-1"
                    id="MaintenanceTabContent"
                  >
                    {/* Failure Tab Pane List */}
                    <div
                      id="MaintenanceEmailLogs-tab-pane"
                      className="tab-pane fade active show h-100 position-relative"
                      tabIndex={0}
                    >
                      <MaintenanceEmailLogs />
                    </div>

                    {/* Maintenance Logs */}
                    <div
                      id="MaintenanceLogs-tab-pane"
                      className="tab-pane fade  h-100 position-relative  "
                      tabIndex={0}
                    >
                      <MaintenanceLogs />
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <MaintenanceEmailMessageModal />
    </>
  );
};

export default MaintenanceScreen;
