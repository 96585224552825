import { Loading } from "./commonTypes";
import { UserFailureData } from "../service/api.dtos";
import { RootState } from "../store/store";
import { UsersFailuresState } from "./usersFailuresSlice";

export const getNestFailuresState = (state: RootState): UsersFailuresState =>
  state.usersFailuresState;

export const getNestFailures = (state: RootState): UserFailureData =>
  state.usersFailuresState.usersFailuresData;

export const getNestFailuresLoading = (state: RootState): Loading =>
  state.usersFailuresState.loading;
