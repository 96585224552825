import Issues from "../charts/Issues";
import { useSelector } from "react-redux";
import { getNestingOverviewState } from "../store/nestingOverview";
import { getIsRecycle } from "../store/common";
import Overview from "../charts/Overview";
import LoadingIcon from "../components/LoadingIcon";
import { OverviewStatistics, UserFailureData } from "../service/api.dtos";
import { areObjectsEqual as isInitialLoad } from "../utils";
import { getNestFailuresState } from "../store/nestFailures";

export default function IssuesContainer() {
  const { nestingOverviewData, loading: overviewLoading } = useSelector(
    getNestingOverviewState
  );
  const { usersFailuresData, loading: issuesLoading } =
    useSelector(getNestFailuresState);
  const isRecycle = useSelector(getIsRecycle);

  return (
    <div className="card bg-dark h-100">
      <div className="card-header border-bottom d-flex">
        <ul className="nav nav-tabs card-header-tabs">
          <li className="nav-item">
            <button
              className="nav-link active theme-py-1"
              aria-current="true"
              data-bs-toggle="tab"
              data-bs-target="#issuesPane"
            >
              <span className="h5 mb-0">Issues</span>
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link theme-py-1"
              aria-current="true"
              data-bs-toggle="tab"
              data-bs-target="#overviewPane"
            >
              <span className="h5 mb-0">Overview</span>
            </button>
          </li>
        </ul>

        <div className="ms-auto">
          {isRecycle &&
            (!isInitialLoad(usersFailuresData, new UserFailureData()) ||
              !isInitialLoad(
                nestingOverviewData,
                new OverviewStatistics()
              )) && (
              <LoadingIcon
                loading={overviewLoading || issuesLoading}
                className="me-3"
              />
            )}
          <span className="badge bg-success me-2">
            Success Trend {nestingOverviewData?.successTrend?.toFixed(2)}&nbsp;%
          </span>{" "}
          <span className="badge bg-danger me-2">
            Error Trend {nestingOverviewData?.errorTrend?.toFixed(2)}&nbsp;%
          </span>
        </div>
      </div>

      <div className="tab-content d-flex flex-grow-1" id="issuesTabContent">
        <div
          className="tab-pane fade theme-me--100 w-100 active show overflow-hidden mb-3 pb-3"
          id="issuesPane"
          role="tabpanel"
          aria-labelledby="issues-tab"
        >
          <Issues />
        </div>
        <div
          className="tab-pane fade theme-me--100  w-100"
          id="overviewPane"
          role="tabpanel"
          aria-labelledby="overview-tab"
        >
          <Overview />
        </div>
      </div>
    </div>
  );
}
