import { getMaintenanceLoading, getMaintenanceMode } from "./maintenance";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { setMaintenanceModalVisibility } from "./maintenanceSlice";

const MaintenanceModeSwitch = () => {
  const dispatch = useAppDispatch();
  const maintenanceMode = useAppSelector(getMaintenanceMode);
  const maintenanceLoading = useAppSelector(getMaintenanceLoading);

  function handleMaintenanceClick(): void {
    dispatch(setMaintenanceModalVisibility(true));
  }

  return (
    <div className="btn btn-secondary border">
      <div className="form-check form-switch ms-auto mb-0">
        <label
          className="theme-pointer form-label text-nowrap mb-0 "
          htmlFor="maintenanceCheckbox"
        >
          Maintenance {maintenanceMode.isEnabled ? "On" : "Off"}
        </label>

        {maintenanceLoading === "pending" ? (
          <div className="ms-n5 float-start ps-2">
            <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
          </div>
        ) : (
          <input
            readOnly
            className="form-check-input theme-pointer"
            type="checkbox"
            id="maintenanceCheckbox"
            checked={maintenanceMode.isEnabled || false}
            onClick={handleMaintenanceClick}
          />
        )}
      </div>
    </div>
  );
};

export default MaintenanceModeSwitch;
