import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getNestingTrafficChartState } from "../store/nestingTraffic";
import LoadingOverlay from "../components/LoadingOverlay";
import { dateLabels, areObjectsEqual as isInitialLoad } from "../utils";
import { getIsRecycle } from "../store/common";
import LoadingIcon from "../components/LoadingIcon";
import { QueueLengthChartData } from "../service/api.dtos";

export default function NestQueueLength() {
  const { nestingTrafficData, loading } = useSelector(
    getNestingTrafficChartState
  );
  const isRecycle = useSelector(getIsRecycle);

  return (
    <div className="card bg-dark h-100">
      <div className="card-header h5 d-flex justify-content-between align-items-center">
        <span>Nest Queue Length</span>
        {isRecycle &&
          !isInitialLoad(nestingTrafficData, new QueueLengthChartData()) && (
            <LoadingIcon loading={loading} />
          )}
      </div>

      <div className="card-body position-relative">
        {/* Loading */}
        {(!isRecycle ||
          isInitialLoad(nestingTrafficData, new QueueLengthChartData())) &&
        loading === "pending" ? (
          <LoadingOverlay loading={loading} />
        ) : (
          <>
            <Line
              redraw={!isRecycle}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    grid: {
                      display: true,
                      color: "rgba(255,99,132,0.2)",
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: dateLabels(nestingTrafficData.labels as string[]),
                datasets: [
                  {
                    label: "Average Seconds To Assign",
                    data: nestingTrafficData.averageSecondsToAssignValues,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    fill: true,
                  },

                  {
                    label: "Max Seconds To Assign",
                    data: nestingTrafficData.maximumSecondsToAssignValues,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 2,
                    fill: true,
                  },

                  {
                    label: "Min Seconds To Assign",
                    data: nestingTrafficData.minimumSecondsToAssignValues,
                    backgroundColor: "rgba(255, 206, 86, 0.2)",
                    borderColor: "rgba(255, 206, 86, 1)",
                    borderWidth: 2,
                    fill: true,
                  },

                  {
                    label: "Queue Length",
                    data: nestingTrafficData.queueLengthValues,
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 2,
                    fill: true,
                  },

                  {
                    label: "Redis Error Queue",
                    data: nestingTrafficData.redisErrorQueueValues,
                    backgroundColor: "rgba(153, 102, 255, 0.2)",
                    borderColor: "rgba(153, 102, 255, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                  {
                    label: "Redis Render Fail Queue",
                    data: nestingTrafficData.redisRenderFailQueueValues,
                    backgroundColor: "rgba(255, 159, 64, 0.2)",
                    borderColor: "rgba(255, 159, 64, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                ],
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
