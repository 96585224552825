import { ErrorResponse, JsonServiceClient } from "@servicestack/client";
import { UserFailureData, UserFailures } from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Loading } from "./commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

// /Admin/Failures/Nesting
export const fetchUsersFailures = createAsyncThunk(
  "usersFailures/fetchUsersFailures",
  async (
    { userId, id, limitToTypeId, limit, page }: UserFailures,
    thunkAPI
  ) => {
    return await client
      .get(new UserFailures({ userId, id, limitToTypeId, limit, page }))
      .then((data) => {
        return data;
      })
      .catch((error: ErrorResponse) => {
        //returns error into payload
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export type UsersFailuresState = {
  userId?: string;
  usersFailuresData: UserFailureData;
  loading: Loading;
  errorMessage: string;
};

const initialState: UsersFailuresState = {
  userId: "",
  usersFailuresData: new UserFailureData(),
  loading: "idle",
  errorMessage: "",
};

const usersFailuresSlice = createSlice({
  name: "usersFailures",
  initialState,
  reducers: {
    disposeUsersFailureState: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersFailures.pending, (state) => {
        state.loading = "pending";
        state.errorMessage = "";
      })
      .addCase(fetchUsersFailures.fulfilled, (state, action) => {
        state.usersFailuresData = action.payload as UserFailureData;
        state.userId = action.meta.arg.userId;
        state.loading = "succeeded";
        state.errorMessage = "";
      })
      .addCase(fetchUsersFailures.rejected, (state, rejectedAction) => {
        state.usersFailuresData = new UserFailureData();

        state.loading = "failed";
        state.errorMessage = rejectedAction.payload as string;
      });
  },
});

export const { disposeUsersFailureState } = usersFailuresSlice.actions;

export default usersFailuresSlice.reducer;
