import { faCheck, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserProfileData } from "../../store/searchUser";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PlanData() {
  const userProfileData = useSelector(getUserProfileData);
  const history = useHistory();

  const handleReportsClick = () => {
    return history.push({
      pathname: "/reports",
      search: `?id=${userProfileData.user.id}`,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-5">
          <legend>Plan Data</legend>
        </div>

        <div className="col-7 text-end pe-3">
          {/* Support Tickets - Link*/}
          <div className="row">
            <div className="col-12">
              <div className="btn-group w-100" role="group">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={handleReportsClick}
                >
                  Reports
                </button>
                <a
                  className={`btn btn-outline-primary btn-sm ${
                    !userProfileData.supportTickets ? "disabled" : ""
                  }`}
                  rel="noreferrer"
                  href={userProfileData.supportTickets}
                  target="_blank"
                >
                  Support Tickets
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className="ms-2"
                    fixedWidth
                  />
                </a>
                <a
                  className={`btn btn-outline-primary btn-sm  ${
                    !userProfileData.freshSalesLink ? "disabled" : ""
                  }`}
                  href={userProfileData.freshSalesLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Fresh Sales
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className="ms-2"
                    fixedWidth
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <dl className="row">
        {/* Active Plan */}
        <dt className="col-sm-5">Active Plan</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.hasActivePlan ? (
            <FontAwesomeIcon icon={faCheck} className="me-2" fixedWidth />
          ) : (
            "--"
          )}
        </dd>

        {/* Plan Type */}
        <dt className="col-sm-5">Plan Type</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.planName ?? "--"}
        </dd>

        {/* Remaining Seconds */}
        <dt className="col-sm-5">Remaining Seconds</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.remainingSeconds ?? "--"}&nbsp;
          <small>sec</small>
        </dd>

        {/* Subscription Last Payment */}
        <dt className="col-sm-5">Subscription Last Payment</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.subscriptionLastPayment
            ? new Date(
                userProfileData.planData.subscriptionLastPayment
              ).toLocaleDateString("en-GB", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "--"}
        </dd>

        {/* Subscription Valid Till */}
        <dt className="col-sm-5">Subscription Valid Till</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.subscriptionValidTill
            ? new Date(
                userProfileData.planData.subscriptionValidTill
              ).toLocaleDateString("en-GB", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "--"}
        </dd>

        {/* Total Minutes */}
        <dt className="col-sm-5">Total Minutes</dt>
        <dd className="col-sm-7">
          {userProfileData.planData.totalMinutes?.toString() ?? "--"}
          &nbsp;<small>min</small>
        </dd>
      </dl>
    </>
  );
}
