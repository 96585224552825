import { getUserProfileData } from "../../store/searchUser";
import { useAppSelector } from "../../hooks/reduxTypedHooks";

export default function QuestionnaireData() {
  const userProfileData = useAppSelector(getUserProfileData);

  return (
    <div className="rounded p-3 border mt-auto mb-3 position-relative">
      <span className="theme-questionnaire h6 bg-dark px-2">
        Questionnaire choices
      </span>
      <div className="row">
        {/*  Machines */}
        <div className="col-4">
          <div className="border-bottom">Machines</div>
          <div className="">
            {userProfileData?.questionnaireMachines?.length > 0 ? (
              userProfileData.questionnaireMachines.map((answer, i) => (
                <div key={i}>{answer}</div>
              ))
            ) : (
              <div>--</div>
            )}
          </div>
        </div>
        {/*  Materials */}
        <div className="col-4">
          <div className="border-bottom">Materials</div>
          <div className="">
            {userProfileData?.questionnaireMaterials?.length > 0 ? (
              userProfileData.questionnaireMaterials.map((answer, i) => (
                <div key={i}>{answer}</div>
              ))
            ) : (
              <div>--</div>
            )}
          </div>
        </div>
        {/*  Usage */}
        <div className="col-4">
          <div className="border-bottom">Usage</div>
          <div className="">
            {userProfileData?.questionnaireUsage?.length > 0 ? (
              userProfileData.questionnaireUsage.map((answer, i) => (
                <div key={i}>{answer}</div>
              ))
            ) : (
              <div>--</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
