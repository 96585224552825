import { useAppType } from "../hooks/useAppType";

const LogoType = () => {
  const appType = useAppType();

  return (
    <>
      {appType === "Live" && (
        <div className="theme-live badge bg-primary">{appType}</div>
      )}
      {appType === "Dev" && (
        <div className="theme-live badge border border-secondary">
          {appType}
        </div>
      )}
      {appType === "Staging" && (
        <div className="theme-live badge bg-secondary">{appType}</div>
      )}
      {appType === "Test" && (
        <div className="theme-live badge bg-secondary">{appType}</div>
      )}
      {appType === "Docker" && (
        <div className="theme-live badge bg-secondary">{appType}</div>
      )}
    </>
  );
};

export default LogoType;
