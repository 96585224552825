import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function CheckBool({ bool }: { bool: boolean }) {
  return (
    <>
      {bool ? (
        <FontAwesomeIcon icon={faCheck} fixedWidth />
      ) : (
        <span className="opacity-50">--</span>
      )}
    </>
  );
}
