import { RootState } from "./store";

export const getTeams = (state: RootState) => state.teams.teams;

export const getTeamsLoading = (state: RootState) => state.teams.teamsLoading;

export const getTeam = (state: RootState) => state.teams.team;

export const getTeamLoading = (state: RootState) => state.teams.teamLoading;

export const getTeamAllowanceLoading = (state: RootState) =>
  state.teams.teamAllowanceLoading;
