import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import LoadingIcon from "../components/LoadingIcon";
import LoadingOverlay from "../components/LoadingOverlay";
import { NestCountChartData } from "../service/api.dtos";
import { getIsRecycle } from "../store/common";
import { getNestCountChartState } from "../store/nestCount";
import { dateLabels, areObjectsEqual as isInitialLoad } from "../utils";

export default function NestPerHour() {
  const { nestCountData, loading } = useSelector(getNestCountChartState);
  const isRecycle = useSelector(getIsRecycle);
  return (
    <div className="card bg-dark card bg-dark h-100">
      <div className="card-header h5 d-flex justify-content-between align-items-center">
        <span>Nest Per Hour</span>
        {isRecycle &&
          !isInitialLoad(nestCountData, new NestCountChartData()) && (
            <LoadingIcon loading={loading} />
          )}
      </div>

      <div className="card-body position-relative">
        {/* Loading */}
        {(!isRecycle ||
          isInitialLoad(nestCountData, new NestCountChartData())) &&
        loading === "pending" ? (
          <LoadingOverlay loading={loading} />
        ) : (
          <>
            <Line
              redraw={!isRecycle}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    grid: {
                      display: true,
                      color: "rgba(255,99,132,0.2)",
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: dateLabels(nestCountData.labels as string[]),
                datasets: [
                  {
                    label: "Assigned Nests",
                    data: nestCountData.assignedNestsValues,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                  {
                    label: "Failed Nests",
                    data: nestCountData.failedNestsValues,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                  {
                    label: "Ran Nests",
                    data: nestCountData.ranNestsValues,
                    backgroundColor: "rgba(255, 206, 86, 0.2)",
                    borderColor: "rgba(255, 206, 86, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                  {
                    label: "Successful Nests",
                    data: nestCountData.successfulNestsValues,
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    borderColor: "rgba(153, 102, 255, 1)",
                    borderWidth: 2,
                    fill: true,
                  },
                ],
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
