export type SortDescriptor = {
  active: boolean;
  order: "asc" | "desc";
  sortable?: boolean;
  sortedIndexes: number[];
};

export type TableHeader = {
  property: string;
  sort: SortDescriptor;
  title: string;
  width?: number;
  cssClass?: string;
  toolTipText?: string;
  tableCellWidth?: string;
};

export const toggleHeaderSort = (
  targetHeader: TableHeader,
  headers: TableHeader[],
  toSort: any[],
  idProperty: string
): TableHeader[] => {
  const headerIndex = headers.findIndex(
    (sHeader) => sHeader.property === targetHeader.property
  );

  const active = !(
    targetHeader.sort.order === "desc" && targetHeader.sort.active
  );

  const order = active
    ? targetHeader.sort.order === "desc"
      ? "asc"
      : "desc"
    : targetHeader.sort.order;

  if (targetHeader.property.length <= 0 || toSort.length <= 0) {
    return [
      ...headers.slice(0, headerIndex).map((sHeader) => ({
        ...sHeader,
        sort: {
          ...sHeader.sort,
          active: false,
        },
      })),
      {
        ...headers[headerIndex],
        sort: {
          active: false,
          order,
          sortable: headers[headerIndex].sort.sortable,
          sortedIndexes: [],
        },
      },
      ...headers.slice(headerIndex + 1).map((sHeader) => ({
        ...sHeader,
        sort: {
          ...sHeader.sort,
          active: false,
        },
      })),
    ];
  }

  const sortMethod = (a, b) => {
    let aProp = a[targetHeader.property];
    let bProp = b[targetHeader.property];
    if (typeof aProp === "number" && typeof bProp === "number") {
      return order === "desc"
        ? aProp < bProp
          ? 1
          : -1
        : aProp > bProp
        ? 1
        : -1;
    }
    if (typeof aProp === "string" && typeof bProp === "string") {
      return order === "desc"
        ? bProp.localeCompare(aProp)
        : aProp.localeCompare(bProp);
    }
    if (typeof aProp === "boolean" && typeof bProp === "boolean") {
      return order === "desc" ? (aProp ? -1 : 1) : bProp ? -1 : 1;
    }
    if (aProp instanceof Date && bProp instanceof Date) {
      return order === "desc"
        ? bProp.getTime() - aProp.getTime()
        : aProp.getTime() - bProp.getTime();
    }
    if (typeof aProp === "object" && typeof bProp === "object") {
      return order === "desc"
        ? JSON.stringify(bProp).localeCompare(JSON.stringify(aProp))
        : JSON.stringify(aProp).localeCompare(JSON.stringify(bProp));
    }
    return order === "desc" ? bProp - aProp : aProp - bProp;
  };

  const sortedItems = active ? toSort.slice().sort(sortMethod) : [];

  const sortedIndexes = sortedItems.map((sortedItem) =>
    toSort.findIndex((item) => item[idProperty] === sortedItem[idProperty])
  );

  return [
    ...headers.slice(0, headerIndex).map((sHeader) => ({
      ...sHeader,
      sort: {
        ...sHeader.sort,
        active: false,
      },
    })),
    {
      ...headers[headerIndex],
      sort: {
        active,
        order,
        sortable: headers[headerIndex].sort.sortable,
        sortedIndexes,
      },
    },
    ...headers.slice(headerIndex + 1).map((sHeader) => ({
      ...sHeader,
      sort: {
        ...sHeader.sort,
        active: false,
      },
    })),
  ];
};
