import { ActiveUserState } from "./activeUsersSlice";
import { RootState } from "../store/store";
import { LoggedInUserCountsChart } from "../service/api.dtos";
import { Loading } from "./commonTypes";

export const getActiveUsersState = (state: RootState): ActiveUserState =>
  state.activeUserState;

export const getActiveUsersData = (state: RootState): LoggedInUserCountsChart =>
  state.activeUserState.activeUserData;

export const getActiveUsersLoadingStatus = (state: RootState): Loading =>
  state.activeUserState.loading;
