import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResponse, JsonServiceClient } from "@servicestack/client";
import { AzureLinks, AzureLinksRequest } from "../service/api.dtos";
import { Loading } from "./commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export const getAzureLinks = createAsyncThunk(
  "azureLinks/getAzureLinks",
  async (_, thunkApi) => {
    return await client
      .get(new AzureLinksRequest())
      .then((data) => {
        return data;
      })
      .catch((error: ErrorResponse) => {
        // UNAUTHORIZED 401

        //returns into payload
        return thunkApi.rejectWithValue(error);
      });
  }
);

export type AzureLinksState = {
  azureLinks: AzureLinks;
  loading: Loading;
  error: string;
};

const initialState: AzureLinksState = {
  azureLinks: new AzureLinks(),
  loading: "idle",
  error: "",
};

const azureLinksSlice = createSlice({
  name: "azureLinks",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAzureLinks.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAzureLinks.fulfilled, (state, action) => {
        state.azureLinks = action.payload as AzureLinks;
        state.loading = "succeeded";
        state.error = "";
      })
      .addCase(getAzureLinks.rejected, (state, rejectedAction) => {
        state.loading = "failed";
        state.error = rejectedAction.payload as string;
      });
  },
});

export default azureLinksSlice.reducer;
