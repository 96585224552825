import {
  ChartRangeData,
  NestCountChart,
  NestCountChartData,
  NestCountChartRanged,
} from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

///Admin/Charts/NestCount
export const getNestCount = createAsyncThunk(
  "nestCount/getNestCount",
  async ({ range, startDate }: NestCountChart, thunkAPI) => {
    return await client
      .get(
        new NestCountChart({
          range,
          startDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const getNestCountRanged = createAsyncThunk(
  "nestCount/getNestCountRanged",
  async ({ minutesPeriod, toDate, fromDate }: ChartRangeData, thunkAPI) => {
    return await client
      .get(
        new NestCountChartRanged({
          minutesPeriod,
          toDate,
          fromDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export type NestCountState = {
  nestCountData: NestCountChartData;
  loading: Loading;
};

const initialState: NestCountState = {
  nestCountData: new NestCountChartData(),
  loading: "idle",
};

const nestCountSlice = createSlice({
  name: "nestCount",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // ================================================================
      // getNestCountRanged
      .addCase(getNestCountRanged.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNestCountRanged.fulfilled, (state, action) => {
        state.nestCountData = action.payload as NestCountChartData;

        state.loading = "succeeded";
      })
      .addCase(getNestCountRanged.rejected, (state) => {
        state.loading = "failed";
      })
      // ================================================================
      // getNestCount
      .addCase(getNestCount.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNestCount.fulfilled, (state, action) => {
        state.nestCountData = action.payload as NestCountChartData;

        state.loading = "succeeded";
      })
      .addCase(getNestCount.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default nestCountSlice.reducer;
