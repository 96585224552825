import { ErrorResponse, JsonServiceClient } from "@servicestack/client";
import { ExportAllUsers, FetchAllUsers, UserBasics } from "../service/api.dtos";

import { Loading } from "./commonTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export type UsersState = {
  allUsers: Array<UserBasics>;
  allUsersLoading: Loading;
  allUsersErrorMessage: string;
  exportUsersLoading: Loading;
};

const initialState: UsersState = {
  allUsers: [],
  allUsersLoading: "idle",
  allUsersErrorMessage: "",
  exportUsersLoading: "idle",
};

export const fetchAllUsers = createAsyncThunk(
  "allUsers/getAllUser",
  async (ignoreLocked: boolean, thunkAPI) => {
    return await client
      .get(new FetchAllUsers({ ignoreLocked }))
      .then((data) => {
        return data;
      })
      .catch((error: ErrorResponse) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const exportUsers = createAsyncThunk(
  "allUsers/exportUsers",

  async (_, thunkAPI) => {
    let contentDispositionHeader: string | null;
    client.responseFilter = function (r) {
      contentDispositionHeader = r.headers.get("content-disposition");
    };

    return await client
      .post(
        new ExportAllUsers({
          ignoreLocked: false,
        })
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = contentDispositionHeader!
          .toString()
          .match(/filename\*=UTF-8''(.+)/)!
          .slice(-1)[0]
          .split(";")[1]
          .trim();

        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const usersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    disposeAllUsersState: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.allUsersLoading = "pending";
        state.allUsersErrorMessage = "";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.allUsers = action.payload as Array<UserBasics>;
        state.allUsersLoading = "succeeded";
        state.allUsersErrorMessage = "";
      })
      .addCase(fetchAllUsers.rejected, (state, rejectedAction) => {
        state.allUsers = [];
        state.allUsersLoading = "failed";
        state.allUsersErrorMessage = rejectedAction.payload as string;
      })
      .addCase(exportUsers.pending, (state) => {
        state.exportUsersLoading = "pending";
      })
      .addCase(exportUsers.fulfilled, (state) => {
        state.exportUsersLoading = "succeeded";
      })
      .addCase(exportUsers.rejected, (state) => {
        state.exportUsersLoading = "failed";
      });
  },
});

export const { disposeAllUsersState } = usersSlice.actions;

export default usersSlice.reducer;
