import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableHeader, toggleHeaderSort } from "../Sort";
import { getTeams, getTeamsLoading } from "../store/team";
import { useAppDispatch, useAppSelector } from "../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListHeader from "../components/ListHeaders";
import LoadingOverlay from "../components/LoadingOverlay";
import { TeamOverview } from "../service/api.dtos";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { fetchTeams } from "../store/teamSlice";
import { useHistory } from "react-router";
import { useTeamsHeaders } from "../features/teamAccount/useTeamsHeader";

const Teams: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const teams = useAppSelector(getTeams);
  const teamsLoading = useAppSelector(getTeamsLoading);
  const history = useHistory();

  const initialSortHeaders = useTeamsHeaders;

  const [sortedTeams, setSortedTeams] = useState<TeamOverview[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [tableHeaders, setTableHeaders] =
    useState<TableHeader[]>(initialSortHeaders);

  const sortingColumn = useMemo(
    () => tableHeaders.find((header) => header.sort.active),
    [tableHeaders]
  );

  const setHeaderSort = useCallback(
    (header: TableHeader) => {
      if (header.property.length > 0) {
        setTableHeaders(toggleHeaderSort(header, tableHeaders, teams, "id"));
      }
    },
    [teams, tableHeaders]
  );
  useEffect(() => {
    typeof sortingColumn !== "undefined"
      ? setSortedTeams(
          sortingColumn.sort.sortedIndexes.map((index) => {
            return teams[index];
          })
        )
      : setSortedTeams(teams);
  }, [teams, sortingColumn]);

  useEffect(() => {
    dispatch(fetchTeams());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function teamClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/team-account?teamId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/team-account",
        search: `?teamId=${id}`,
      });
    }
  }

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setSearchInput(value);
  }

  function clear() {
    setSearchInput("");
  }

  useEffect(() => {
    setSortedTeams(() =>
      teams.filter((f) =>
        f.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, teams]);

  return (
    <section className="container-lg py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          <h4>Teams</h4>
          <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
            .
          </div>

          <div className="input-group input-group-sm">
            {/* search type */}
            <input
              className="form-control"
              onChange={handleSearchInput}
              value={searchInput}
              placeholder="Search"
            />

            {/* Clear */}
            <button
              title="Clear search"
              className="btn btn-secondary border bg-dark"
              type="button"
              onClick={clear}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
          </div>
        </div>

        <div className="card-body h-100 d-flex position-relative m-3">
          <div className="border rounded theme-overflow">
            <LoadingOverlay loading={teamsLoading} />
            {!teams?.length && <h4 className="text-center p-5">No results</h4>}
            {teamsLoading === "failed" && (
              <h4 className="text-center p-5">Failed to load users</h4>
            )}
            {/* Teams */}
            {teamsLoading === "succeeded" && teams?.length > 0 && (
              <div className="table-responsive theme-overflow theme-overflow-table">
                <table
                  id="table-Teams"
                  className="table table-dark table-hover mb-0"
                >
                  <thead className="sticky-top border-bottom">
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <ListHeader
                          key={index}
                          header={header}
                          toggleHeaderSort={setHeaderSort}
                          canSort={sortedTeams.length > 1}
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody className="small">
                    {sortedTeams.map((team, i) => (
                      <tr key={team.id ?? i}>
                        {/* Name */}
                        <td>
                          {team.id ? (
                            <button
                              className="btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
                              onClick={(e) => teamClick(e, team.id)}
                              onAuxClick={(e) => teamClick(e, team.id)}
                            >
                              {team.name}
                            </button>
                          ) : (
                            <span className="fw-bold btn btn-link btn-sm disabled text-decoration-none text-body">
                              {team.name}
                            </span>
                          )}
                        </td>
                        {/* Allowed Members */}
                        <td className="text-end text-nowrap">
                          {team.allowedMembers}
                        </td>
                        {/* Team Member Count */}
                        <td className="text-end text-nowrap">
                          {team.teamMemberCount}
                        </td>
                        {/* Nest Count */}
                        <td className="text-end text-nowrap">
                          {team.noteCount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teams;
