import { GetNestingOverview, OverviewStatistics } from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export const getNestingOverview = createAsyncThunk(
  "nestingOverview/getNestingOverview",
  async (
    { overviewPeriod, refreshPeriod, startDate }: GetNestingOverview,
    thunkAPI
  ) => {
    return await client
      .get(
        new GetNestingOverview({
          overviewPeriod,
          refreshPeriod,
          startDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export type NestingOverviewState = {
  nestingOverviewData: OverviewStatistics;
  loading: Loading;
};

const initialState: NestingOverviewState = {
  nestingOverviewData: new OverviewStatistics(),
  loading: "idle",
};

const nestingOverviewSlice = createSlice({
  name: "nestingOverview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNestingOverview.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNestingOverview.fulfilled, (state, action) => {
        state.nestingOverviewData = action.payload as OverviewStatistics;
        state.loading = "succeeded";
      })
      .addCase(getNestingOverview.rejected, (state) => {
        state.loading = "failed";
        state.nestingOverviewData = new OverviewStatistics();
      });
  },
});

export default nestingOverviewSlice.reducer;
