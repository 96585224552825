import {
  ChartRangeData,
  GetActiveUserChart,
  GetNestingOverview,
  NestCountChart,
  NestingTrafficChart,
  UserFailures,
} from "../service/api.dtos";
import React, { useCallback, useEffect, useState } from "react";
import {
  getActiveUsers,
  getActiveUsersRanged,
} from "../store/activeUsersSlice";
import {
  getFromDate,
  getIsRecycle,
  getStartDate,
  getTimeRange,
  getToDate,
} from "../store/common";
import { getNestCount, getNestCountRanged } from "../store/nestCountSlice";
import {
  getNestingTraffic,
  getNestingTrafficRanged,
} from "../store/nestingTrafficSlice";
import {
  setFromDate,
  setRecycle,
  setStartDate,
  setTimeRange,
  setToDate,
} from "../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import { fetchUsersFailures } from "../store/usersFailuresSlice";
import { getNestingOverview } from "../store/nestingOverviewSlice";
import { useInterval } from "../hooks/useInterval";

export default function FilterBar() {
  const dispatch = useDispatch();

  const TimeRangeState = useSelector(getTimeRange);
  const StartDate = useSelector(getStartDate);
  const FromDate = useSelector(getFromDate);
  const ToDate = useSelector(getToDate);

  const isRecycle = useSelector(getIsRecycle);
  const [canRecycle, setCanRecycle] = useState(false);
  const [startDateInput, setStartDateInput] = useState<string>(StartDate);
  const [fromDateInput, setFromDateInput] = useState<string>(FromDate);
  const [toDateInput, setToDateInput] = useState<string>(ToDate);
  const [minutesPeriod, setMinutesPeriod] = useState("60");
  const [groupType, setGroupType] = useState<"Ranged" | "RangeLess" | "">("");
  //const debouncedInput = useDebounce(fromDateInput, 2000);

  const LimitFailures = "999,998,0,1,3,9";
  //Disable Refresh chart button if StartDate is not today
  useEffect(() => {
    const isToday = startDateInput
      ? new Date().toDateString() === new Date(startDateInput).toDateString()
      : false;

    setCanRecycle(isToday);
    if (!isToday) {
      dispatch(setRecycle(false));
    }
  }, [dispatch, startDateInput]);

  function handleRadio(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setTimeRange(parseInt(e.target.value)));
  }

  const subtractHours = useCallback(
    (numOfHours: number) => {
      if (!StartDate) {
        return null;
      }
      const dateTimeNow = new Date();
      const StartDateNow = new Date(StartDate);

      StartDateNow.setHours(dateTimeNow.getHours());
      StartDateNow.setMinutes(dateTimeNow.getMinutes());
      StartDateNow.setMilliseconds(dateTimeNow.getMilliseconds());

      StartDateNow.setHours(dateTimeNow.getHours() - numOfHours);

      const newDate = StartDateNow
        ? new Date(
            StartDateNow.getTime() - StartDateNow.getTimezoneOffset() * 60000
          )
            ?.toISOString()
            .slice(0, -1)
        : null;

      return newDate;
    },
    [StartDate]
  );

  const getChartDataRanged = useCallback(() => {
    setGroupType("Ranged");
    dispatch(setRecycle(false));
    dispatch(setFromDate(fromDateInput));
    dispatch(setToDate(toDateInput));
    dispatch(
      getActiveUsersRanged({
        minutesPeriod: Number(minutesPeriod),
        fromDate: fromDateInput,
        toDate: toDateInput,
      } as ChartRangeData)
    );

    // Nest Queue Length
    dispatch(
      getNestingTrafficRanged({
        minutesPeriod: Number(minutesPeriod),
        fromDate: fromDateInput,
        toDate: toDateInput,
      } as ChartRangeData)
    );

    // Nest Per Hour Ranged
    dispatch(
      getNestCountRanged({
        minutesPeriod: Number(minutesPeriod),
        fromDate: fromDateInput,
        toDate: toDateInput,
      } as ChartRangeData)
    );
  }, [dispatch, fromDateInput, toDateInput, minutesPeriod]);

  // 24hr, 6hr, 1hr
  const getChartData = useCallback(() => {
    setGroupType("RangeLess");
    dispatch(setStartDate(startDateInput));
    // Active Users
    dispatch(
      getActiveUsers({
        range: TimeRangeState,
        startTime: subtractHours(TimeRangeState),
      } as GetActiveUserChart)
    );

    // Nest Queue Length
    dispatch(
      getNestingTraffic({
        range: TimeRangeState,
        startDate: subtractHours(TimeRangeState),
      } as NestingTrafficChart)
    );

    // Nest Per Hour
    dispatch(
      getNestCount({
        range: TimeRangeState,
        startDate: subtractHours(TimeRangeState),
      } as NestCountChart)
    );
  }, [dispatch, subtractHours, TimeRangeState, startDateInput]);

  const handleRecycle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (isRecycle) {
      } else {
        setGroupType("RangeLess");
        getChartData();
      }

      dispatch(setRecycle(!isRecycle));
    },
    [dispatch, isRecycle, getChartData]
  );
  useEffect(() => {
    // Overview
    dispatch(
      getNestingOverview({
        overviewPeriod: TimeRangeState * 60,
        startDate: subtractHours(TimeRangeState),
        refreshPeriod: 1440, // 24hrs
      } as GetNestingOverview)
    );
  }, [dispatch, subtractHours, TimeRangeState]);

  //OnLoad
  useEffect(() => {
    //Get chart data onload and if TimeRange or StartDate changes
    getChartData();
    setGroupType("RangeLess");

    //Get issues chart data onload
    dispatch(
      fetchUsersFailures({ limitToTypeId: LimitFailures } as UserFailures)
    );

    // Remove Dash lane elements
    setTimeout(() => {
      const dashLaneElement = document.querySelectorAll(
        "[data-dashlanecreated]"
      );
      dashLaneElement.forEach((e) => e.remove());
    }, 400);
  }, []); // eslint-disable-line

  useInterval(
    () => {
      //Get chart data interval and if TimeRange or StartDate changes
      getChartData();
      //Get issues chart data on interval change
      dispatch(
        fetchUsersFailures({ limitToTypeId: LimitFailures } as UserFailures)
      );
    },
    isRecycle ? 60000 : null
  );

  return (
    //   Filter Bar
    <div className="card bg-dark mb-3">
      <div className="card-body d-flex align-items-center">
        <h4>Dashboard</h4>
        {/* Bar spacer */}
        <div className="rounded flex-fill mx-3 bg-secondary h-50 text-secondary">
          .
        </div>

        <div
          className="btn-toolbar flex-nowrap"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          {/* Ranged */}
          {/* Start/From Date Input */}
          <div
            className={`input-group input-group-sm me-3 ${
              groupType === "Ranged" ? "theme-outline-active" : ""
            }`}
          >
            <input
              id="input-dateFromSearch"
              className="form-control col"
              type="datetime-local"
              onChange={(e) => setFromDateInput(e.target.value)}
              value={fromDateInput}
              max={new Date().toISOString().substring(0, 10)}
            />

            {/* To Date Input */}
            <input
              id="input-dateToSearch"
              className="form-control col"
              type="datetime-local"
              onChange={(e) => setToDateInput(e.target.value)}
              value={toDateInput}
              max={new Date().toISOString().substring(0, 10)}
            />

            {/* Minutes  */}
            <input
              id="input-minutesPeriod"
              type="number"
              className="form-control col"
              min="1"
              style={{ maxWidth: "100px" }}
              value={minutesPeriod}
              onChange={(e) => setMinutesPeriod(e.target.value)}
            />

            {/* Apply button */}
            <button
              className="btn btn-primary text-white"
              onClick={getChartDataRanged}
              disabled={fromDateInput === "" || toDateInput === ""}
            >
              Apply
            </button>
          </div>

          <div
            className={`input-group input-group-sm me-3 ${
              groupType === "RangeLess" ? "theme-outline-active" : ""
            }`}
          >
            {/* To Date Input */}
            <input
              id="input-startDate"
              className="form-control col"
              type="datetime-local"
              onChange={(e) => setStartDateInput(e.target.value)}
              value={startDateInput}
              max={new Date().toISOString().substring(0, 10)}
            />
            {/* Radio Button */}
            <input
              type="radio"
              className="btn-check"
              name="btnRadio"
              id="btnRadio1"
              autoComplete="off"
              value="24"
              checked={TimeRangeState === 24}
              onChange={handleRadio}
            />
            <label
              className={`btn btn-outline-primary text-nowrap ${
                TimeRangeState === 24 && "text-body"
              }`}
              htmlFor="btnRadio1"
            >
              24 hr
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnRadio"
              id="btnRadio2"
              autoComplete="off"
              value="6"
              checked={TimeRangeState === 6}
              onChange={handleRadio}
            />
            <label
              className={`btn btn-outline-primary text-nowrap ${
                TimeRangeState === 6 && "text-body"
              }`}
              htmlFor="btnRadio2"
            >
              6 hr
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnRadio"
              id="btnRadio3"
              autoComplete="off"
              value="1"
              checked={TimeRangeState === 1}
              onChange={handleRadio}
            />
            <label
              className={`btn btn-outline-primary text-nowrap ${
                TimeRangeState === 1 && "text-body"
              }`}
              htmlFor="btnRadio3"
            >
              1 hr
            </label>
            {/* Apply button */}
            <button
              className="btn btn-primary text-white "
              onClick={getChartData}
              disabled={startDateInput === ""}
            >
              Apply
            </button>
            {/* Refresh chart interval */}
            <button
              className={`btn btn-secondary border ${
                isRecycle ? "active" : ""
              } ${!canRecycle ? "opacity-25" : ""}`}
              data-bs-toggle="button"
              onClick={handleRecycle}
              disabled={!canRecycle}
            >
              <FontAwesomeIcon
                icon={faRecycle}
                fixedWidth
                className={`${isRecycle && "text-primary"} `}
              />
            </button>
          </div>
          <div className="input-group "></div>
        </div>
      </div>
    </div>
  );
}
