import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  faCheck,
  faCircleNotch,
  faFileDownload,
  faSearch,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchNestingReportCSV,
  fetchNestingReportJSON,
  setJsonReports,
} from "./reportsSlice";
import { getReports, getReportsCsvLoading, getReportsLoading } from "./reports";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "../../components/LoadingOverlay";
import { NestingReport } from "../../service/api.dtos";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { dateFormatter } from "../../components/DateFormatter";
import { useHistory } from "react-router";
import { useQuery } from "../../utils";

const ReportsScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const reports = useAppSelector(getReports);
  const reportsLoading = useAppSelector(getReportsLoading);
  const reportsCsvLoading = useAppSelector(getReportsCsvLoading);
  const [dateInputFrom, setDateInputFrom] = useState<string>(
    new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString(
      "fr-CA"
    )
  );

  const [dateInputTo, setDateInputTo] = useState<string>(
    new Date().toLocaleDateString("fr-CA")
  );
  const [userId, setUserId] = useState<string>(query.get("id") ?? "");

  function clear() {
    setDateInputFrom("");
    setDateInputTo("");
    setUserId("");
    dispatch(setJsonReports(undefined));
    query.delete("id");

    return history.push({
      search: query.toString(),
    });
  }

  useEffect(() => {
    //umount
    return () => {
      dispatch(setJsonReports(undefined));
    };
  }, [dispatch]);

  const reportRef = React.useRef(true);

  const handleFetchReports = useCallback(() => {
    if (!dateInputFrom || !dateInputTo) return;

    dispatch(
      fetchNestingReportJSON({
        from: dateInputFrom,
        to: dateInputTo,
        userId: query.get("id") ?? userId,
      } as NestingReport)
    )
      .unwrap()
      .catch((e) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: e?.responseStatus?.message ?? "Oops?",
          })
        );
      });
  }, [dateInputFrom, dateInputTo, userId, dispatch, query]);

  useEffect(() => {
    if (reportRef.current) handleFetchReports();
    reportRef.current = false;
  }, [handleFetchReports]);

  function formatAsPercentage(num) {
    return num ? (
      <div className="text-end">
        {new Intl.NumberFormat("default", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(num)}
      </div>
    ) : (
      <div className="opacity-50 text-center">--</div>
    );
  }

  function handleCSVDownload(e) {
    e.preventDefault();

    dispatch(
      fetchNestingReportCSV({
        from: dateInputFrom,
        to: dateInputTo,
        userId: userId,
      } as NestingReport)
    );
  }

  function accountClick(id: string) {
    return history.push({
      pathname: "/user-account",
      search: `?userId=${id}`,
    });
  }

  return (
    <section className="container-fluid py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          {/* Title */}
          <h4>Reports</h4>
          <div className="rounded flex-fill mx-3 bg-secondary w-75 h-50 text-secondary">
            .
          </div>
          {/* Date Filter */}
          <div className="input-group input-group-sm">
            <>
              {/* User Id Input */}
              <input
                className="form-control"
                type="text"
                placeholder="User Id"
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
                style={{ minWidth: "330px" }}
              />
              {/* Date Input From */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputFrom(e.target.value)}
                value={dateInputFrom}
                max={new Date().toISOString().substring(0, 10)}
              />

              {/* Date Input To */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputTo(e.target.value)}
                value={dateInputTo}
                max={new Date().toISOString().substring(0, 10)}
              />
              {/* Reports amount */}
              <div
                className="input-group-text bg-dark"
                style={{ minWidth: "70px" }}
              >
                {reports?.nests?.length}
              </div>

              {/* Clear */}
              <button
                className="btn btn-secondary border bg-dark"
                type="button"
                onClick={clear}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>

              {/* Download */}
              <button
                title="Report CSV"
                type="button"
                className="btn btn-secondary border bg-dark"
                disabled={reportsCsvLoading === "pending"}
                onClick={handleCSVDownload}
              >
                {reportsCsvLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faFileDownload} fixedWidth />
                )}
              </button>

              {/* Search Button */}
              <button
                className="btn btn-secondary border bg-dark"
                id="search"
                onClick={handleFetchReports}
                disabled={reportsLoading === "pending"}
              >
                {reportsLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                )}
              </button>
            </>
          </div>
        </div>
        {/* Table */}
        <div className="card-body d-flex flex-grow-1 position-relative m-3 h-100">
          <div className="border rounded theme-overflow">
            {reportsLoading === "pending" && (
              <LoadingOverlay loading={"pending"} />
            )}
            {reportsLoading !== "pending" &&
              (reportsLoading === "failed" || !reports?.nests?.length) && (
                <h4 className="text-center p-5">No results</h4>
              )}
            {reportsLoading !== "pending" &&
              reportsLoading !== "failed" &&
              reports?.nests?.length > 0 && (
                <div className="theme-overflow theme-overflow-table table-responsive  small">
                  <table
                    id="table-reports"
                    className="table table-dark table-hover mb-0"
                  >
                    <thead className="sticky-top border-bottom">
                      <tr>
                        <th className="text-nowrap" scope="row">
                          First Name
                        </th>

                        <th scope="row">Surname</th>

                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Created
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Worker Received
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Started
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          User Informed
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Failed
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Finished
                        </th>

                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Efficiency
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Parts
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Sheets
                        </th>
                        <th
                          className="text-center text-nowrap"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Unique Nests
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Allocated
                        </th>
                        <th
                          className="text-center"
                          scope="col"
                          style={{ width: "1%" }}
                        >
                          Cancelled
                        </th>

                        <th
                          scope="col"
                          style={{ maxWidth: "4rem", width: "4rem" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports?.nests?.map((nest) => (
                        <tr key={nest.runId} className="theme-hover-row">
                          <th scope="row">{nest?.userFirstName}</th>
                          <th scope="row">{nest?.userSurname}</th>

                          <td className="text-nowrap">
                            {dateFormatter(nest?.createdDate)}
                          </td>
                          <td className="text-nowrap">
                            {dateFormatter(nest?.workerReceivedDate)}
                          </td>
                          <td className="text-nowrap">
                            {dateFormatter(nest?.startedDate)}
                          </td>
                          <td className="text-nowrap">
                            {dateFormatter(nest?.userInformedDate)}
                          </td>
                          <td className="text-nowrap">
                            {dateFormatter(nest?.failedDate)}
                          </td>
                          <td className="text-nowrap">
                            {dateFormatter(nest?.finishedDate)}
                          </td>

                          <td className="text-nowrap">
                            {formatAsPercentage(nest.efficiency)}
                          </td>
                          <td className="text-nowrap text-center">
                            {nest.totalPartsNested && nest.totalPartsToNest ? (
                              <>
                                {nest.totalPartsNested} /{" "}
                                {nest.totalPartsToNest}
                              </>
                            ) : (
                              <div className="opacity-50 text-center">--</div>
                            )}
                          </td>
                          <td className="text-nowrap text-center">
                            {nest.sheetCount && nest.sheetUsedCount ? (
                              <>
                                {nest.sheetUsedCount} / {nest.sheetCount}
                              </>
                            ) : (
                              <div className="opacity-50 text-center">--</div>
                            )}
                          </td>

                          <td className="text-nowrap text-end">
                            {nest.uniqueNests ? nest.uniqueNests : 0}
                          </td>
                          <td className="text-nowrap text-end">
                            {nest.allocatedSeconds}
                          </td>
                          <td className="text-nowrap text-center">
                            {nest?.cancelled ? (
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            ) : (
                              <span className="opacity-50">--</span>
                            )}
                          </td>

                          {/* Account button */}
                          <td className="align-middle text-end">
                            <button
                              className="btn btn-sm btn-secondary float-end text-body py-0 border align-middle"
                              onClick={() => accountClick(nest.createdById)}
                            >
                              <FontAwesomeIcon icon={faUser} fixedWidth />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </div>
        {/*  */}
      </div>
    </section>
  );
};
export default ReportsScreen;
