import { RootState } from "../store/store";
import { TimeRange } from "../service/api.dtos";

export const getTimeRange = (state: RootState): TimeRange =>
  state.commonState.timeRange;

export const getStartDate = (state: RootState): string =>
  state.commonState.startDate;

export const getFromDate = (state: RootState): string =>
  state.commonState.fromDate;

export const getToDate = (state: RootState): string => state.commonState.toDate;

export const getIsRecycle = (state: RootState): boolean =>
  state.commonState.isRecycle;

export const getConfirmModal = (state: RootState) =>
  state.commonState.confirmModal;
