import { faCircleNotch, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  fetchEmailLog,
  fetchPreviewEmailMaintenanceMessage,
  setEmailMaintenanceMessage,
  setMaintenanceEmailMessage,
  setMaintenanceEmailMessageModalVisibility,
  setMaintenanceEmailMessageSubject,
} from "./maintenanceSlice";
import {
  getEmailMaintenanceMessageLoading,
  getMaintenanceEmailMessageModalVisibility,
} from "./maintenance";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useCallback, useEffect, useRef, useState } from "react";

import { EmailMaintenancePreview } from "../../service/api.dtos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Main from "../../components/TypeBackground";
import { Modal } from "bootstrap";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { useAppType } from "../../hooks/useAppType";
import { useHistory } from "react-router";

export default function MaintenanceEmailMessageModal() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const appType = useAppType();

  const [previewMessage, setPreviewMessage] =
    useState<EmailMaintenancePreview>();

  const [responseMessage, setResponseMessage] = useState<string>("");

  const maintenanceEmailMessageModeLoading = useAppSelector(
    getEmailMaintenanceMessageLoading
  );

  const maintenanceEmailMessageModalVisibility = useAppSelector(
    getMaintenanceEmailMessageModalVisibility
  );

  const showModal = useCallback(() => {
    window.location.hash = "#maintenanceEmailMessageModal";

    // get preview
    dispatch(fetchPreviewEmailMaintenanceMessage())
      .unwrap()
      .then((data) => {
        setPreviewMessage(data);
      });

    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {});

    bsModal.show();
  }, [dispatch]);

  const hiddenModal = useCallback(() => {
    dispatch(setMaintenanceEmailMessageModalVisibility(false));
    setResponseMessage("");
    if (window.location.hash) {
      history.push(window.location.pathname);
    }
  }, [dispatch, history]);

  const hideModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);

    bsModal?.hide();
  }, []);

  useEffect(() => {
    const modalEle = modalRef.current;
    if (modalEle) {
      modalEle.addEventListener("hidden.bs.modal", hiddenModal);
      window.addEventListener("popstate", hideModal);
    }

    if (maintenanceEmailMessageModalVisibility) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      modalEle && modalEle.removeEventListener("hidden.bs.modal", hiddenModal);
      window.removeEventListener("popstate", hideModal);
    };
  }, [
    dispatch,
    maintenanceEmailMessageModalVisibility,
    showModal,
    hideModal,
    hiddenModal,
  ]);

  function handleSaveMaintenanceEmailMessage(): void {
    dispatch(setEmailMaintenanceMessage())
      .unwrap()
      .then((response) => {
        dispatch(setMaintenanceEmailMessage(""));
        dispatch(setMaintenanceEmailMessageSubject(""));
        dispatch(fetchEmailLog());
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "success",
            text: `Maintenance email message sent to ${response.emailsAttempted}`,
          })
        );

        setResponseMessage(response.emailsAttempted?.toString() || "");
      })
      .catch((error) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: `Maintenance email message failed to send. ${error}`,
          })
        );
      });
  }

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="maintenanceEmailMessageModal"
      tabIndex={-1}
      aria-labelledby="maintenanceMailMessageModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-xl modal-dialog-centered">
        <div className="modal-content bg-dark">
          <Main>
            <div className="modal-header">
              <h5 className="modal-title" id="maintenanceModalLabel">
                Maintenance Email Message - {appType}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {responseMessage ? (
                <div className="alert alert-success" role="alert">
                  Maintenance email message sent to {responseMessage}
                </div>
              ) : (
                <>
                  {previewMessage?.subject && (
                    <div className="w-100 g-3 mb-3">
                      <div className="col d-flex">
                        <label
                          htmlFor="staticEmail2"
                          className="form-control-plaintext col me-3"
                        >
                          Subject
                        </label>
                        <input
                          value={previewMessage?.subject}
                          type="text"
                          readOnly
                          className="form-control "
                          id="staticEmail2"
                        />
                      </div>
                    </div>
                  )}
                  {/* //in bracket ({}) so the newline characters are not
                          interpreted as plain text: */}
                  {/* {"foo\n bar"} */}
                  <iframe
                    title="preview"
                    className="w-100"
                    srcDoc={previewMessage?.htmlContent ?? ""}
                    width="100%"
                    height="100%"
                    onLoad={(e) => {
                      const iframe = e.currentTarget as HTMLIFrameElement;

                      iframe.height =
                        (window.document.body.scrollHeight - 500).toString() ??
                        "0";
                    }}
                  />
                  Are you sure you want to send this email message to all users?
                </>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary fw-bold"
                data-bs-dismiss="modal"
                disabled={
                  maintenanceEmailMessageModeLoading === "pending" ||
                  !!responseMessage
                }
              >
                Cancel
              </button>
              <button
                id="button-confirmEmailMessage"
                type="button"
                className="btn btn-primary text-body fw-bold"
                onClick={handleSaveMaintenanceEmailMessage}
                disabled={
                  maintenanceEmailMessageModeLoading === "pending" ||
                  !!responseMessage
                }
              >
                {maintenanceEmailMessageModeLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
                ) : (
                  <>
                    Send&nbsp;
                    <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
                  </>
                )}
              </button>
            </div>
          </Main>
        </div>
      </div>
    </div>
  );
}
