import {
  ChartRangeData,
  NestingTrafficChart,
  NestingTrafficChartRange,
  QueueLengthChartData,
} from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../store/commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

///Admin/Charts/NestingTraffic
export const getNestingTraffic = createAsyncThunk(
  "nestingTraffic/getNestingTraffic",
  async ({ range, startDate }: NestingTrafficChart, thunkAPI) => {
    return await client
      .get(
        new NestingTrafficChart({
          range: range,
          startDate: startDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const getNestingTrafficRanged = createAsyncThunk(
  "nestingTraffic/getNestingTrafficRanged",
  async ({ minutesPeriod, toDate, fromDate }: ChartRangeData, thunkAPI) => {
    return await client
      .get(
        new NestingTrafficChartRange({
          minutesPeriod: minutesPeriod,
          toDate: toDate,
          fromDate: fromDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export type NestingTrafficState = {
  nestingTrafficData: QueueLengthChartData;
  loading: Loading;
};

const initialState: NestingTrafficState = {
  nestingTrafficData: new QueueLengthChartData(),
  loading: "idle",
};

const nestingTrafficSlice = createSlice({
  name: "nestingTraffic",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // ============================================================
      //   getNestingTraffic
      .addCase(getNestingTraffic.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNestingTraffic.fulfilled, (state, action) => {
        state.nestingTrafficData = action.payload;

        state.loading = "succeeded";
      })
      .addCase(getNestingTraffic.rejected, (state) => {
        state.loading = "failed";
      })
      // ============================================================
      // getNestingTrafficRanged
      .addCase(getNestingTrafficRanged.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNestingTrafficRanged.fulfilled, (state, action) => {
        state.nestingTrafficData = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getNestingTrafficRanged.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default nestingTrafficSlice.reducer;
