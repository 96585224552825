import React, { useEffect, useRef } from "react";
import { faCircleNotch, faTools } from "@fortawesome/free-solid-svg-icons";
import {
  fetchMaintenanceLog,
  setMaintenanceModalVisibility,
  setMaintenanceMode,
} from "./maintenanceSlice";
import {
  getMaintenanceModalVisibility,
  getMaintenanceMode,
  getMaintenanceModeLoading,
} from "./maintenance";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { ChangeMaintenance } from "../../service/api.dtos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Main from "../../components/TypeBackground";
import { Modal } from "bootstrap";
import { useAppType } from "../../hooks/useAppType";

export default function MaintenanceModal() {
  const dispatch = useAppDispatch();
  const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const appType = useAppType();

  const maintenanceModeLoading = useAppSelector(getMaintenanceModeLoading);

  const maintenanceModalVisibility = useAppSelector(
    getMaintenanceModalVisibility
  );

  const maintenanceMode = useAppSelector(getMaintenanceMode);

  function maintenanceClickHandle() {
    dispatch(
      setMaintenanceMode({
        setValueTo: !maintenanceMode.isEnabled,
      } as ChangeMaintenance)
    ).then(() => {
      dispatch(fetchMaintenanceLog());
    });
  }

  const showModal = () => {
    const modalElement = modalRef.current;
    const bsModal = new Modal(modalElement, {});

    bsModal.show();
  };

  const hideModal = () => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);
    bsModal?.hide();
  };

  useEffect(() => {
    const modalEle = modalRef.current;
    //catch external & cancel click
    modalEle.addEventListener("hidden.bs.modal", () => {
      dispatch(setMaintenanceModalVisibility(false));
    });

    if (maintenanceModalVisibility === true) {
      showModal();
    } else {
      hideModal();
    }

    return () => modalEle.removeEventListener("hidden.bs.modal", dispatch);
  }, [dispatch, maintenanceModalVisibility]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="maintenanceModal"
      tabIndex={-1}
      aria-labelledby="maintenanceModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-dark">
          <Main>
            <div className="modal-header">
              <h5 className="modal-title" id="maintenanceModalLabel">
                Maintenance Mode - {appType}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to&nbsp;
              {maintenanceMode.isEnabled ? "de-activate" : "activate"}
              &nbsp;maintenance mode?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary fw-bold"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary text-body fw-bold"
                onClick={maintenanceClickHandle}
              >
                {maintenanceModeLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faTools} fixedWidth />
                    &nbsp;
                    {maintenanceMode.isEnabled ? "De-activate" : "Activate"}
                  </>
                )}
              </button>
            </div>
          </Main>
        </div>
      </div>
    </div>
  );
}
