import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function dateLabels(dateLabels: Array<string>) {
  const dates = dateLabels?.map((date: string) => {
    return new Date(date).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
  });

  return dates;
}
export function previousDate(date: string): string {
  let previousDate: Date = new Date(date);
  previousDate.setDate(previousDate.getDate() - 1);

  const returnDate = previousDate.toLocaleDateString("en-GB", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return returnDate ?? "";
}

export function areObjectsEqual(firstObject: any, secondObject: any): boolean {
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
}
