import {
  ChartRangeData,
  GetActiveUserChart,
  GetActiveUserChartRanged,
  LoggedInUserCountsChart,
} from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../store/commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export const getActiveUsers = createAsyncThunk(
  "activeUsers/getActiveUsers",
  async ({ range, startTime }: GetActiveUserChart, thunkAPI) => {
    return await client
      .get(
        new GetActiveUserChart({
          range,
          startTime,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const getActiveUsersRanged = createAsyncThunk(
  "activeUsers/getActiveUsersRanged",
  async ({ minutesPeriod, toDate, fromDate }: ChartRangeData, thunkAPI) => {
    return await client
      .get(
        new GetActiveUserChartRanged({
          minutesPeriod: minutesPeriod,
          toDate: toDate,
          fromDate: fromDate,
        })
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export type ActiveUserState = {
  activeUserData: LoggedInUserCountsChart;
  loading: Loading;
};

const initialState: ActiveUserState = {
  activeUserData: new LoggedInUserCountsChart(),
  loading: "idle",
};

const activeUsersSlice = createSlice({
  name: "activeUsers",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // ================================================================
      // getActiveUsers
      .addCase(getActiveUsers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getActiveUsers.fulfilled, (state, action) => {
        state.activeUserData = action.payload as LoggedInUserCountsChart;

        state.loading = "succeeded";
      })
      .addCase(getActiveUsers.rejected, (state) => {
        state.loading = "failed";
      })
      // ================================================================
      // getActiveUsersRanged
      .addCase(getActiveUsersRanged.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getActiveUsersRanged.fulfilled, (state, action) => {
        state.activeUserData = action.payload as LoggedInUserCountsChart;
        state.loading = "succeeded";
      })
      .addCase(getActiveUsersRanged.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default activeUsersSlice.reducer;
