import DisplayCard from "../../components/DisplayCard";
import { getTeam } from "../../store/team";
import { useAppSelector } from "../../hooks/reduxTypedHooks";

export default function TeamStats() {
  const team = useAppSelector(getTeam);
  return (
    <div className="p-3">
      <div className="row">
        <div className="col-12  ">
          <legend>Nesting Stats</legend>
          <div className=" d-flex  mx-n2 flex-row">
            {/* Failed Nests */}
            <div className="flex-grow-1 px-2">
              <DisplayCard title="Failed Nests">
                {team?.nestingStats?.counts.failedNests}
              </DisplayCard>
            </div>
            {/* Failure Percentage */}
            <div className="flex-grow-1 px-2">
              <DisplayCard title="Failure Percentage">
                <>
                  {Math.ceil(
                    team?.nestingStats?.counts.failurePercentage ?? 0
                  ).toString()}
                  &nbsp;<small>%</small>
                </>
              </DisplayCard>
            </div>
            {/* Total Nests */}
            <div className="flex-grow-1 px-2">
              <DisplayCard title="Total Nests">
                {team?.nestingStats?.counts.totalNests}
              </DisplayCard>
            </div>
            {/* Nesting Projects Created */}
            <div className="flex-grow-1 px-2">
              <DisplayCard title="Nesting Projects Created">
                {team?.nestingStats?.counts.nestingProjectsCreated}
              </DisplayCard>
            </div>
            {/* Used Allowance */}
            <div className="flex-grow-1 px-2">
              <DisplayCard title="Used Allowance">
                {team?.nestingStats?.usedAllowance}
              </DisplayCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
