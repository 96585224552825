import { Loading } from "./commonTypes";
import { RootState } from "../store/store";
import { UserProfileData } from "../service/api.dtos";
import { UsersState } from "./searchUserSlice";

export const getUserState = (state: RootState): UsersState => state.userState;

export const getUserProfileData = (state: RootState): UserProfileData =>
  state.userState.userProfileData;

export const getUserProfileDataLoading = (state: RootState): Loading =>
  state.userState.loading;

export const getAddMinutesLoading = (state: RootState): Loading =>
  state.userState.addMinutesLoading;

export const getPauseAccountLoading = (state: RootState): Loading =>
  state.userState.pauseAccountLoading;

export const getFreeAccountLoading = (state: RootState): Loading =>
  state.userState.freeAccountLoading;

export const getMaxFileUploadSizeLoading = (state: RootState): Loading =>
  state.userState.maxFileUploadSizeLoading;

export const getMaxEntitiesCountLoading = (state: RootState): Loading =>
  state.userState.maxEntitiesCountLoading;

export const getAcceptOrDenyTrialLoading = (state: RootState): Loading =>
  state.userState.trialAcceptOrDenyLoading;

export const getMarkForDeletionLoading = (state: RootState): Loading =>
  state.userState.markForDeletionLoading;

export const getExportLimitLoading = (state: RootState): Loading =>
  state.userState.exportLimitLoading;

export const getNestingSecondsLoading = (state: RootState): Loading =>
  state.userState.nestingSecondsLoading;

export const getReportMaxNestsLoading = (state: RootState): Loading =>
  state.userState.reportMaxNestsLoading;
