import { getMaintenanceLogLoading, getMaintenanceLogs } from "./maintenance";
import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { dateFormatter } from "../../components/DateFormatter";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { fetchMaintenanceLog } from "./maintenanceSlice";

const MaintenanceLogs = () => {
  const dispatch = useAppDispatch();
  const maintenanceLogs = useAppSelector(getMaintenanceLogs);
  const loading = useAppSelector(getMaintenanceLogLoading);

  useEffect(() => {
    dispatch(fetchMaintenanceLog());
  }, [dispatch]);

  return (
    <>
      {loading === "pending" ? (
        <div className="p-3">Loading...</div>
      ) : (
        <div className="table-responsive theme-overflow theme-overflow-table">
          <table className="table table-striped  small">
            <thead className="sticky-top bg-dark">
              <tr>
                <th>Mode</th>
                <th>Agent</th>
                <th>Message</th>
                <th>Date Sent</th>
                <th style={{ width: "4rem" }}></th>
              </tr>
            </thead>
            <tbody>
              {maintenanceLogs.map((log) => (
                <React.Fragment key={log.id}>
                  <tr className="theme-hover-row">
                    <th>
                      {log.onOrOff ? (
                        <span className="text-primary">On</span>
                      ) : (
                        <span>Off</span>
                      )}
                    </th>
                    <th>{log.triggeredBy}</th>
                    <td>{log.currentMessage}</td>
                    <td>{dateFormatter(log.dateSent)}</td>
                    <td className="align-middle">
                      {/* Message */}
                      <button
                        className={`btn btn-sm btn-primary text-body py-0 me-3 ${
                          log.currentMessage ? "" : "theme-visually-hidden"
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${log.id}`}
                      >
                        <FontAwesomeIcon icon={faInfo} fixedWidth />
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={4} className="p-0 border-0">
                      <div className="collapse" id={`collapse-${log.id}`}>
                        <div className="p-3 theme-white-space border-bottom">
                          {log.currentMessage}
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default memo(MaintenanceLogs);
