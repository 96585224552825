import { TableHeader } from "../../Sort";

export function useTeamsHeaders() {
  const initialTeamsSortHeaders = [
    {
      property: "name",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Team",
      width: 7,
    },
    {
      property: "allowedMembers",
      cssClass: "text-end",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Allowed Members",
      tableCellWidth: "1%",
    },
    {
      property: "teamMemberCount",
      cssClass: "text-end",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Members",
      tableCellWidth: "1%",
    },
    {
      property: "noteCount",
      cssClass: "text-end",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Notes",
      tableCellWidth: "1%",
    },
  ] as TableHeader[];

  return initialTeamsSortHeaders;
}
