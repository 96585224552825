import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { TimeRange } from "../service/api.dtos";

export type ConfirmModal = {
  visibility: boolean;
  message: string;
  callback?: Function;
};
type CommonState = {
  timeRange: TimeRange;
  startDate: string;
  fromDate: string;
  toDate: string;

  isRecycle: boolean;
  confirmModal: ConfirmModal;
};

const initialState: CommonState = {
  timeRange: 24,
  //HTML input type date expects yyyy-mm-dd
  startDate: new Date().toISOString().substring(0, 16),
  fromDate: new Date(new Date().setDate(new Date().getDate() - 5))
    .toISOString()
    .substring(0, 16),
  toDate: new Date().toISOString().substring(0, 16),

  //Pick up from localStorage
  isRecycle: JSON.parse(localStorage.getItem("isRecycle") ?? "false"),

  //ConfirmModal
  confirmModal: {
    visibility: false,
    message: "",
    callback: undefined,
  },
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setTimeRange(state, action: PayloadAction<TimeRange>) {
      state.timeRange = action.payload;
    },
    setStartDate(state, action: PayloadAction<string>) {
      state.startDate = action.payload;
    },
    setFromDate(state, action: PayloadAction<string>) {
      state.fromDate = action.payload;
    },
    setToDate(state, action: PayloadAction<string>) {
      state.toDate = action.payload;
    },
    setRecycle(state, action: PayloadAction<boolean>) {
      state.isRecycle = action.payload;
      localStorage.setItem("isRecycle", JSON.stringify(action.payload));
    },
    setConfirmModal(state, action: PayloadAction<ConfirmModal>) {
      state.confirmModal = action.payload;
      localStorage.setItem("isRecycle", JSON.stringify(action.payload));
    },
  },
});

export const {
  setTimeRange,
  setStartDate,
  setRecycle,
  setConfirmModal,
  setFromDate,
  setToDate,
} = commonSlice.actions;

export default commonSlice.reducer;
