import { TableHeader } from "../../Sort";

export function useTeamHeaders() {
  const initialTeamSortHeaders = [
    {
      property: "displayName",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Name",
    },

    {
      property: "joinedTeamDate",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Joined Date",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },
    {
      property: "useDarkMode",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Dark",
      toolTipText: "Dark Mode",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },
    {
      property: "locked",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Locked",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "lastTokenValidTill",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Token Valid Till",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },
    {
      property: "createdDate",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Created Date",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "freshSalesId",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Freshsales",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "hasStartedImport",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Import",
      toolTipText: "Started Import",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "hasCompletedImport",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Imported",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "hasCompletedNest",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Nested",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "hasCompletedExport",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Exported",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },

    {
      property: "hasUpdatedSettings",
      sort: {
        active: false,
        order: "desc",
        sortedIndexes: [],
      },
      title: "Settings",
      toolTipText: "Updated Settings",
      cssClass: "text-center",
      tableCellWidth: "1%",
    },
  ] as TableHeader[];

  return initialTeamSortHeaders;
}
