import { FunctionComponent, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { getConfirmModal } from "../../store/common";
import { setConfirmModal } from "../../store/commonSlice";

const ConfirmModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const confirmModal = useAppSelector(getConfirmModal);

  const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const showModal = () => {
    const modalElement = modalRef.current;
    const bsModal = new Modal(modalElement, {});

    bsModal.show();
  };

  const hideModal = () => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);
    bsModal?.hide();
  };

  useEffect(() => {
    const modalEle = modalRef.current;
    //catch external & cancel click
    modalEle.addEventListener("hidden.bs.modal", () => {
      dispatch(
        setConfirmModal({ visibility: false, message: "", callback: undefined })
      );
    });

    if (confirmModal.visibility === true) {
      showModal();
    } else {
      hideModal();
    }

    return () => modalEle.removeEventListener("hidden.bs.modal", dispatch);
  }, [dispatch, confirmModal.visibility]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="maintenanceModal"
      tabIndex={-1}
      aria-labelledby="maintenanceModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-dark">
          <div className="modal-header">
            <h5 className="modal-title" id="maintenanceModalLabel">
              Confirm
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{confirmModal.message}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary fw-bold"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              id="button-confirm"
              type="button"
              className="btn btn-primary text-body fw-bold"
              data-bs-dismiss="modal"
              onClick={() => {
                confirmModal.callback && confirmModal.callback();
              }}
            >
              <FontAwesomeIcon icon={faCheck} fixedWidth />
              &nbsp;Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
