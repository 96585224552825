import {
  GetTeamList,
  GetTeamUserDetails,
  TeamOverview,
  UpdateTeamAllowance,
} from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./commonTypes";

type TeamState = {
  team: TeamOverview | null;
  teamAllowanceLoading: Loading;
  teamLoading: Loading;
  teams: TeamOverview[];
  teamsLoading: Loading;
};
const initialState: TeamState = {
  team: null,
  teamAllowanceLoading: "idle",
  teamLoading: "idle",
  teams: [],
  teamsLoading: "idle",
};
const client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export const fetchTeams = createAsyncThunk(
  "nestingOverview/getTeams",
  async (_, thunkAPI) => {
    return await client
      .get(new GetTeamList())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchTeamDetails = createAsyncThunk(
  "team/fetchTeamUserDetails",
  async (teamId: string, thunkAPI) => {
    return await client
      .get(new GetTeamUserDetails({ teamId }))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setTeamAllowance = createAsyncThunk(
  "team/setTeamAllowance",
  async ({ teamId, newAllowance }: Partial<UpdateTeamAllowance>, thunkAPI) => {
    return await client
      .put(new UpdateTeamAllowance({ teamId, newAllowance }))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET: /teams
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.teamsLoading = "pending";
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teamsLoading = "succeeded";
        state.teams = action.payload;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.teamsLoading = "failed";
      });
    // GET: Team
    builder
      .addCase(fetchTeamDetails.pending, (state) => {
        state.teamLoading = "pending";
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        state.teamLoading = "succeeded";
        state.team = action.payload;
      })
      .addCase(fetchTeamDetails.rejected, (state) => {
        state.teamLoading = "failed";
      });
    // PUT: Team
    builder
      .addCase(setTeamAllowance.pending, (state) => {
        state.teamAllowanceLoading = "pending";
      })
      .addCase(setTeamAllowance.fulfilled, (state, action) => {
        state.teamAllowanceLoading = "succeeded";
        state.team = action.payload;
      })
      .addCase(setTeamAllowance.rejected, (state) => {
        state.teamAllowanceLoading = "failed";
      });
  },
});

export default teamsSlice.reducer;
