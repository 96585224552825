import { getUserProfileData } from "../store/searchUser";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FailedNestsList() {
  const userProfileData = useSelector(getUserProfileData);
  const history = useHistory();
  function rowClick(id: string) {
    return history.push({
      pathname: "/failures",
      search: `?userId=${id}`,
    });
  }

  return (
    <div className=" d-flex flex-grow-1 ">
      {/* Users Failures */}
      <div className="table-responsive w-100 theme-overlay">
        <table className="table table-dark table-hover mb-0">
          <thead className="sticky-top">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Date Failed</th>
              <th scope="col">Create Date</th>
            </tr>
          </thead>
          <tbody className="small">
            {userProfileData?.failedNestingSessions &&
              userProfileData.failedNestingSessions.map(
                (failedNestingSession, i) => (
                  <tr
                    className="theme-pointer"
                    key={failedNestingSession.id + i}
                    onClick={() => rowClick(failedNestingSession.userId)}
                  >
                    <td className="font-monospace">
                      {failedNestingSession.id}
                    </td>

                    <td>
                      {new Date(
                        failedNestingSession.createdDate
                      ).toLocaleString("en-GB")}
                    </td>
                    <td>
                      {new Date(failedNestingSession.dateFailed).toLocaleString(
                        "en-GB"
                      )}
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
