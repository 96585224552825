import { FunctionComponent, MutableRefObject, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { Toast } from "bootstrap";
import { getToastMessages } from "./toastMessages";
import { removeToastMessage } from "./toastMessagesSlice";

const ToastMessagesScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector(getToastMessages);
  const toastRef = useRef() as MutableRefObject<HTMLDivElement>;

  const showToast = () => {
    const bsToast = Toast.getOrCreateInstance(toastRef.current, {
      animation: true,
      delay: messages[0]?.timeout ?? 6000,
    });
    bsToast.show();
  };

  const hideToast = () => {
    const bsToast = Toast.getInstance(toastRef.current);
    bsToast?.hide();
  };

  useEffect(() => {
    const toastEle = toastRef.current;

    if (toastEle) {
      //catch external & cancel click
      toastEle.addEventListener("hidden.bs.toast", () => {
        messages.forEach((message) => {
          dispatch(
            removeToastMessage({
              id: message.id,
            })
          );
        });
      });

      if (messages.length > 0) {
        showToast();
      } else if (messages.length === 0) {
        hideToast();
      }

      return () => toastEle.removeEventListener("hidden.bs.toast", dispatch);
    }
  }, [dispatch, messages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="position-relative mn-t3"
      style={{ zIndex: 1100 }}
    >
      <div className="toast-container position-absolute top-0 end-0 p-3">
        {/* toast */}
        <div
          className="toast  "
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          ref={toastRef}
          data-bs-animation="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Notification</strong>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          {messages.map((message) => (
            <div
              key={message.id}
              className={`toast-body bg-${message.severity}-subtle`}
            >
              <div
                className={`theme-white-space-pre-line text-${message.severity}`}
              >
                {message.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToastMessagesScreen;
