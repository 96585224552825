import { FunctionComponent, useMemo } from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableHeader } from "../Sort";

const ListHeader: FunctionComponent<{
  canSort: boolean;
  header: TableHeader;
  toggleHeaderSort: (header: TableHeader) => void;
}> = (props) => {
  const { header, toggleHeaderSort, canSort } = props;

  const { title, width, cssClass, sort, toolTipText, tableCellWidth } = header;

  const directionalArrow = useMemo(() => {
    return {
      asc: <FontAwesomeIcon icon={faArrowUp} fixedWidth size="xs" />,
      desc: <FontAwesomeIcon icon={faArrowDown} fixedWidth size="xs" />,
    }[sort.order];
  }, [sort.order]);

  return (
    <th
      title={toolTipText ?? title}
      className={`${cssClass} ${canSort ? "theme-pointer" : ""}`}
      onClick={() => {
        // If property to sort by and canSort then allow sorting
        if (header.property !== "" && canSort) {
          toggleHeaderSort(header);
        }
      }}
      style={{
        minWidth: `${typeof width !== "undefined" ? width + "rem" : ""}`,
        width: `${typeof tableCellWidth !== "undefined" ? tableCellWidth : ""}`,
      }}
    >
      {sort.active ? (
        // With direction arrow
        <small className="text-nowrap">
          {title} {directionalArrow}
        </small>
      ) : (
        <small className="text-nowrap">{title}</small>
      )}
    </th>
  );
};

export default ListHeader;
