import { TableHeader, toggleHeaderSort } from "../../Sort";
import { useCallback, useEffect, useMemo, useState } from "react";

import CheckBool from "../../components/CheckBool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListHeader from "../../components/ListHeaders";
import { User } from "../../service/api.dtos";
import { dateFormatter } from "../../components/DateFormatter";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { getTeam } from "../../store/team";
import { useAppSelector } from "../../hooks/reduxTypedHooks";
import { useHistory } from "react-router";
import { useTeamHeaders } from "./useTeamHeaders";

export default function TeamTable() {
  const team = useAppSelector(getTeam);
  const history = useHistory();
  const initialSortHeaders = useTeamHeaders;

  const [sortedMembers, setSortedMembers] = useState<User[]>([]);

  const [tableHeaders, setTableHeaders] =
    useState<TableHeader[]>(initialSortHeaders);

  const sortingColumn = useMemo(
    () => tableHeaders.find((header) => header.sort.active),
    [tableHeaders]
  );

  const setHeaderSort = useCallback(
    (header: TableHeader) => {
      if (header.property.length > 0) {
        setTableHeaders(
          toggleHeaderSort(header, tableHeaders, team?.members ?? [], "id")
        );
      }
    },
    [team?.members, tableHeaders]
  );
  useEffect(() => {
    if (!team?.members) return;

    typeof sortingColumn !== "undefined"
      ? setSortedMembers(
          sortingColumn.sort.sortedIndexes.map((index) => {
            return team.members[index];
          })
        )
      : setSortedMembers(team.members);
  }, [team?.members, sortingColumn]);

  function accountClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/user-account?userId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/user-account",
        search: `?userId=${id}`,
      });
    }
  }

  return (
    <div className=" d-flex flex-grow-1 ">
      <div className="table-responsive w-100 theme-overlay">
        <table
          id="table-teamAccountInfo"
          className="table table-dark table-hover mb-0"
        >
          <thead className="sticky-top border-bottom">
            <tr>
              {tableHeaders.map((header, index) => (
                <ListHeader
                  key={index}
                  header={header}
                  toggleHeaderSort={setHeaderSort}
                  canSort={sortedMembers.length > 1}
                />
              ))}
            </tr>
          </thead>
          <tbody className="small">
            {sortedMembers.map((member) => (
              <tr key={member.id}>
                {/* Display name */}
                <td className="text-nowrap">
                  <button
                    className="btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
                    onClick={(e) => accountClick(e, member.id)}
                    onAuxClick={(e) => accountClick(e, member.id)}
                  >
                    {member?.displayName}
                  </button>
                </td>
                {/* Joined Date */}
                <td className="text-nowrap">
                  {dateFormatter(member.joinedTeamDate)}
                </td>
                {/* Dark Mode */}
                <td className="text-center">
                  <CheckBool bool={member?.useDarkMode} />
                </td>
                {/* Locked */}
                <td className="text-center">
                  <CheckBool bool={member?.locked} />
                </td>
                {/* Token Valid Till */}
                <td className="text-nowrap">
                  {dateFormatter(member.lastTokenValidTill)}
                </td>
                {/* Created Date */}
                <td className="text-nowrap">
                  {dateFormatter(member.createdDate)}
                </td>
                {/* Fresh Sales ID */}
                <td className="font-monospace text-center">
                  <a
                    title={member?.freshSalesId?.toString()}
                    className="btn btn-outline-primary text-decoration-none fw-bold btn-sm theme-btn-hover py-0  "
                    rel="noreferrer"
                    target="_blank"
                    href={`https://efficientsoftwarelimited-org.myfreshworks.com/crm/sales/contacts/${member?.freshSalesId}`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                  </a>
                </td>

                {/* Started Import */}
                <td className="text-center">
                  <CheckBool bool={member?.hasStartedImport} />
                </td>
                {/* Completed Import */}
                <td className="text-center">
                  <CheckBool bool={member?.hasCompletedImport} />
                </td>
                {/* Completed Nest */}
                <td className="text-center">
                  <CheckBool bool={member?.hasCompletedNest} />
                </td>
                {/* Completed Export */}
                <td className="text-center">
                  <CheckBool bool={member?.hasCompletedExport} />
                </td>
                {/* Updated Settings */}
                <td className="text-center">
                  <CheckBool bool={member?.hasUpdatedSettings} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
