import React, { VoidFunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "../store/commonTypes";

type Props = { loading: Loading };

const LoadingOverlay: VoidFunctionComponent<Props> = ({ loading }) => {
  return loading === "pending" ? (
    <div className="theme-loading-overlay">
      <div>
        <FontAwesomeIcon
          icon={faSync}
          fixedWidth
          spin
          size="3x"
          className="text-primary"
        />
      </div>
    </div>
  ) : null;
};
export default LoadingOverlay;
