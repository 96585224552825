import { FunctionComponent, ReactNode } from "react";

const PageTitle: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  let location = window.location.pathname?.split("/")[1];

  let locationCapitalised = location[0]
    ? location[0].toUpperCase() + location.slice(1)
    : "";

  switch (process.env.REACT_APP_BUILD_TYPE) {
    case "production":
      locationCapitalised += " - Live";
      break;
    case "staging":
      locationCapitalised += " - Staging";
      break;
    case "development":
      locationCapitalised += " - Dev";
      break;
    case "testing":
      locationCapitalised += " - Testing";
      break;
    case "docker":
      locationCapitalised += " - Docker";
      break;
    default:
      locationCapitalised += " - Unknown";
      break;
  }

  document.title = locationCapitalised;

  return <>{children}</>;
};

export default PageTitle;
