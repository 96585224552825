import {
  AnnouncementEmailsLogs,
  FeatureSetting,
  MaintenanceModeLog,
} from "../../service/api.dtos";

import { Loading } from "../../store/commonTypes";
import { MaintenanceState } from "./maintenanceSlice";
import { RootState } from "../../store/store";

// all state
export const getMaintenanceState = (state: RootState): MaintenanceState =>
  state.maintenanceState;

export const getMaintenanceLoading = (state: RootState): Loading =>
  state.maintenanceState.maintenanceLoading;

export const getMaintenanceModeLoading = (state: RootState): Loading =>
  state.maintenanceState.maintenanceModeLoading;

export const getClearMaintenanceMessageLoading = (state: RootState): Loading =>
  state.maintenanceState.clearMaintenanceMessageLoading;

export const getMaintenanceMessageLoading = (state: RootState): Loading =>
  state.maintenanceState.maintenanceMessageLoading;

export const getMaintenanceMode = (state: RootState): FeatureSetting =>
  state.maintenanceState.maintenanceMode;

export const getMaintenanceModalVisibility = (state: RootState): boolean =>
  state.maintenanceState.maintenanceModalVisibility;

export const getMaintenanceLogs = (state: RootState): MaintenanceModeLog[] =>
  state.maintenanceState.maintenanceLog;

export const getMaintenanceLogLoading = (state: RootState): Loading =>
  state.maintenanceState.maintenanceLogLoading;

export const getEmailLogs = (state: RootState): AnnouncementEmailsLogs[] =>
  state.maintenanceState.emailLog;

export const getEmailLogLoading = (state: RootState): Loading =>
  state.maintenanceState.emailLogLoading;

export const getMaintenanceEmailMessageSubject = (state: RootState): string =>
  state.maintenanceState.maintenanceEmailMessageSubject;

export const getMaintenanceEmailMessage = (state: RootState): string =>
  state.maintenanceState.maintenanceEmailMessage;

export const getMaintenanceEmailMessageModalVisibility = (
  state: RootState
): boolean => state.maintenanceState.maintenanceEmailMessageModalVisibility;

export const getEmailMaintenanceMessageLoading = (state: RootState): Loading =>
  state.maintenanceState.maintenanceEmailMessageLoading;
