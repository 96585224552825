import { ErrorTypes, FailureData, UserFailures } from "../service/api.dtos";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  faCircleNotch,
  faInfo,
  faSearch,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "../components/LoadingOverlay";
import { fetchUsersFailures } from "../store/usersFailuresSlice";
import { getNestFailuresState } from "../store/nestFailures";
import { useAppDispatch } from "../hooks/reduxTypedHooks";
import { useHistory } from "react-router-dom";
import { useQuery } from "../utils";
import { useSelector } from "react-redux";

export default function Failures() {
  enum IDType {
    userId = 1,
    id = 2,
  }

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [usersFailures, setUsersFailures] = useState<FailureData[]>([]);

  const { loading } = useSelector(getNestFailuresState);

  const query = useQuery();

  //Onload
  const [args, setArgs] = useState<UserFailures>({
    userId: query.get("userId") ?? "",
    id: query.get("id") ?? "",
    limitToTypeId: query.get("limitToTypeId") ?? undefined,
    limit: query.get("limit") ?? undefined,
    page: Number(query.get("page") ?? 1),
  } as UserFailures);

  const containerRef = useRef<HTMLDivElement>(null);

  const [searchInput, setSearchInput] = useState(
    query.get("id") ?? query.get("userId") ?? ""
  );
  const [idTypeState, setIdTypeState] = useState<IDType>(
    query.get("id")
      ? IDType.id
      : query.get("userId")
      ? IDType.userId
      : IDType.id
  );

  // Remove Dash lane elements
  useEffect(() => {
    setTimeout(() => {
      const test = document.querySelectorAll("[data-dashlanecreated]");

      test.forEach((e) => e.remove());
    }, 400);
  }, []);

  function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e && e.preventDefault();
    //dispatch(fetchUsersFailures(args));

    dispatch(fetchUsersFailures(args))
      .unwrap()
      .then((res) => {
        const Failures = [...res.failures].reverse();

        setUsersFailures(Failures);
      });
  }
  const THRESHOLD = 1;
  // The scroll listener
  const handleScroll = useCallback(
    (currentTarget) => {
      const { scrollHeight, clientHeight, scrollTop } = currentTarget.target;

      if (
        Math.abs(scrollHeight - clientHeight - scrollTop) < THRESHOLD &&
        usersFailures.length > 0
      ) {
        setArgs((prev) => {
          return { ...prev, page: Number(prev.page) + 1 } as UserFailures;
        });
      }
    },
    [usersFailures]
  );

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = containerRef.current;

    if (!div) return;

    div.addEventListener("scroll", handleScroll);

    return () => {
      div.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading]);

  useEffect(() => {
    const argsEmpty = Object.values(args).every(
      (x) => x === undefined || x === ""
    );

    let stringBuilder = argsEmpty ? [] : ["?"];

    if (args.userId) {
      stringBuilder.push(`userId=${args.userId}`);
    }
    if (args.id) {
      stringBuilder.push(`id=${args.id}`);
    }
    if (args.limitToTypeId) {
      stringBuilder.push(`limitToTypeId=${args.limitToTypeId}`);
    }
    if (args.limit) {
      stringBuilder.push(`limit=${args.limit}`);
    }

    if (args.page) {
      stringBuilder.push(`page=${args.page}`);
    }

    let i = stringBuilder.length;

    while (i--) {
      if (i > 1) stringBuilder.splice(i, 0, "&");
    }

    // Can be overridden with handleSubmit

    dispatch(fetchUsersFailures(args))
      .unwrap()
      .then((res) => {
        const olderFailures = [...res.failures].reverse();

        setUsersFailures((prev) => [...prev, ...olderFailures]);
      });

    return history.push({
      pathname: "/failures",
      search: stringBuilder.join(""),
    });
  }, [dispatch, args, history, searchInput]);

  function clear() {
    setSearchInput("");
    setArgs(new UserFailures({ page: 1 }));
    setIdTypeState(IDType.id);
    setUsersFailures([]);
  }

  useEffect(() => {
    // unmount
    return () => {
      setUsersFailures([]);
    };
  }, []);

  function handleKeypress(e: any): void {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  function handleInput(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;

    if (name === "search") {
      setSearchInput(value);

      // update Local state

      setArgs({
        ...args,
        [IDType[idTypeState]]: e.currentTarget.value,
      } as UserFailures);
    } else if (name === "limit") {
      setArgs({
        ...args,
        [name]: value ? parseInt(value, 10) : null,
      } as UserFailures);
    }
  }

  const errorTypes = [
    { name: "All Error Types", value: "", id: 0 },
    { name: "Unknown", value: "0", id: 1 },
    { name: "Failure Cache Miss", value: "1", id: 2 },
    { name: "Import File Failure", value: "2", id: 3 },
    { name: "Nesting", value: "3", id: 4 },
    { name: "Importer Missing Parts", value: "4", id: 5 },
    { name: "Negative Feedback", value: "5", id: 6 },
    { name: "Neutral Feedback", value: "6", id: 7 },
    { name: "Positive Feedback", value: "7", id: 8 },
    { name: "Feedback", value: "8", id: 9 },
    { name: "Paddle", value: "9", id: 10 },
    { name: "User Deletions", value: "999", id: 11 },
    { name: "Send To Support", value: "10", id: 12 },
    { name: "Export", value: "11", id: 13 },
    { name: "Malformed Json", value: "12", id: 14 },
    { name: "Missing Json", value: "13", id: 15 },
    { name: "Nest Project Conversion", value: "14", id: 16 },
    { name: "Nest Project Open", value: "15", id: 17 },
    { name: "Missing Part JSON ", value: "16", id: 18 },
    { name: "Server Reboot", value: "998", id: 19 },
    { name: "User Mark As Deletion", value: "17", id: 20 },
    { name: "CRM", value: "18", id: 21 },
  ];

  function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target;

    let newArgs = { ...args, page: 1 } as UserFailures;

    setUsersFailures([]);

    if (name === "idType") {
      switch (value) {
        case "1":
          setIdTypeState(IDType.userId);

          newArgs = {
            ...newArgs,
            id: "",
            userId: newArgs.id,
          } as UserFailures;

          break;
        case "2":
          setIdTypeState(IDType.id);

          newArgs = {
            ...newArgs,
            id: newArgs.userId,
            userId: "",
          } as UserFailures;
          break;

        default:
          break;
      }
    } else if (name === "errorType") {
      newArgs = {
        ...newArgs,
        limitToTypeId: value,
      } as UserFailures;
    }

    setArgs(newArgs);
  }

  function isInfo(userFailureData: FailureData) {
    if (
      userFailureData.exceptionType == null &&
      userFailureData.exceptionMessage == null &&
      userFailureData.innerExceptionMessage == null &&
      userFailureData.innerStackTrace == null
    ) {
      return false;
    }
    return true;
  }

  function downloadClick(id: string): void {
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/Admin/Failures/Download?Id=${id}`,
      "_blank"
    );
  }
  function accountClick(id: string) {
    return history.push({
      pathname: "/user-account",
      search: `?userId=${id}`,
    });
  }

  return (
    <section className="container-fluid py-3 h-100">
      <div className="card text-body bg-dark  h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          {/* Title */}
          <h4>Failures</h4>
          {/* Bar spacer */}
          <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
            .
          </div>

          {/* Search Filter */}
          <div className="col-8">
            {/* Search Bar */}
            <form
              className="input-group input-group-sm w-100"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <select
                className="form-select theme-failure-bar-id-types"
                onChange={handleSelect}
                name="idType"
                value={idTypeState}
              >
                <option value="2">ID</option>
                <option value="1">User Id</option>
              </select>

              <input
                type="text"
                className="form-control theme-input"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                autoComplete="off"
                name="search"
                value={searchInput}
                onChange={handleInput}
                onKeyPress={handleKeypress}
              />

              {/* ErrorType */}

              <select
                style={{ minWidth: "240px" }}
                className="form-select theme-failure-bar-error-types"
                onChange={handleSelect}
                name="errorType"
                value={args.limitToTypeId ?? ""}
              >
                {errorTypes.map((errorType, i) => (
                  <option key={errorType.id} value={errorType.value}>
                    {errorType.name}
                  </option>
                ))}
              </select>

              {/* Limit */}
              <input
                list="limit-amounts"
                name="limit"
                placeholder="Limit"
                className="form-control theme-failure-bar-limit"
                onChange={handleInput}
                value={args.limit ?? ""}
              />

              <datalist id="limit-amounts">
                <option value="0" />
                <option value="10" />
                <option value="50" />
                <option value="100" />
                <option value="1000" />
              </datalist>

              {/* Clear */}

              <button
                className="btn btn-secondary border bg-dark"
                type="button"
                onClick={clear}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>
              {/* Search no button */}
              <button
                className="btn btn-secondary border bg-dark"
                id="search"
                type="submit"
                disabled={loading === "pending"}
              >
                {loading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                )}
              </button>
            </form>
          </div>
        </div>

        <>
          <div className="card-body d-flex flex-grow-1 position-relative m-3 h-100">
            <div className="border rounded theme-overflow">
              {loading === "pending" && usersFailures?.length === 0 && (
                <LoadingOverlay loading={"pending"} />
              )}

              {/* Only show loader for no failures length otherwise infinity scrollbar jumps to the top */}
              {loading !== "pending" &&
                (loading === "failed" || !usersFailures?.length) && (
                  <h4 className="text-center p-5">No results</h4>
                )}
              {loading !== "failed" && usersFailures?.length > 0 && (
                <div
                  className="theme-overflow theme-overflow-table table-responsive"
                  ref={containerRef}
                >
                  <table
                    id="table-failures"
                    className="table table-dark table-hover mb-0"
                  >
                    <thead className="sticky-top border-bottom">
                      <tr>
                        <th scope="col" style={{ width: "1%" }}>
                          <small className="text-nowrap">Name</small>
                        </th>
                        <th scope="col">
                          <small className="text-nowrap">Type</small>
                        </th>
                        <th
                          scope="col"
                          style={{ width: "1%" }}
                          className="text-nowrap text-center"
                        >
                          <small className="text-nowrap">Requested Time</small>
                        </th>
                        <th scope="col">
                          <small className="text-nowrap">Issue</small>
                        </th>
                        <th
                          scope="col"
                          style={{ width: "1%" }}
                          className="text-center"
                        >
                          <small className="text-nowrap">Resolution</small>
                        </th>
                        <th style={{ width: "200px" }}></th>
                      </tr>
                    </thead>
                    {/*  */}
                    <tbody className="small">
                      {usersFailures &&
                        Array.from(
                          usersFailures.map((userFailureData) => (
                            <React.Fragment key={userFailureData.id}>
                              <tr
                                className="theme-hover-row"
                                id={userFailureData.id}
                              >
                                {/* Name */}
                                <th scope="row" className="fw-bold text-nowrap">
                                  {userFailureData.name}
                                </th>

                                <td className="text-nowrap">
                                  {userFailureData.typeOfError
                                    .toString()
                                    .split(/(?=[A-Z])/)
                                    .join(" ")}
                                </td>
                                <td className="text-nowrap">
                                  {new Date(
                                    userFailureData.occurred
                                  ).toLocaleString("en-GB")}
                                </td>
                                {/* Issue */}
                                <td>
                                  {userFailureData.typeOfError.toString() ===
                                  ErrorTypes[ErrorTypes.UserDeletions] ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: userFailureData.reason,
                                      }}
                                    />
                                  ) : (
                                    <div className="theme-white-space">
                                      {userFailureData.reason}
                                    </div>
                                  )}
                                </td>
                                {/* Resolution */}
                                <td className="text-nowrap text-center">
                                  {userFailureData.signupResolution}
                                </td>
                                <td className="align-middle">
                                  <div
                                    className="d-flex justify-content-end"
                                    role="group"
                                  >
                                    {/* Download */}
                                    {userFailureData.showDownloadLink && (
                                      <button
                                        className="btn btn-sm btn-outline-primary py-0 text-body me-3"
                                        onClick={() =>
                                          downloadClick(userFailureData.id)
                                        }
                                      >
                                        Download
                                      </button>
                                    )}
                                    {/* Info button */}

                                    <button
                                      className={`btn btn-sm btn-primary text-body py-0 me-3 ${
                                        isInfo(userFailureData)
                                          ? ""
                                          : "theme-visually-hidden"
                                      }`}
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse-${userFailureData.id}`}
                                    >
                                      <FontAwesomeIcon
                                        icon={faInfo}
                                        fixedWidth
                                      />
                                    </button>

                                    {/* Account button */}
                                    <button
                                      className="btn btn-sm btn-secondary text-body py-0 border"
                                      onClick={() =>
                                        accountClick(userFailureData.userId)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        fixedWidth
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              {/* Further Info */}
                              <tr>
                                <td colSpan={6} className="p-0 border-0">
                                  <div
                                    className="collapse"
                                    id={`collapse-${userFailureData.id}`}
                                  >
                                    <dl className="p-3 m-0 border-bottom">
                                      {/* Exception Type */}
                                      <dt>Exception Type</dt>
                                      <dd className="theme-white-space">
                                        {userFailureData.exceptionType ?? "--"}
                                      </dd>

                                      {/* Exception Message */}
                                      <dt>Exception Message</dt>
                                      <dd className="theme-white-space">
                                        {userFailureData.exceptionMessage ??
                                          "--"}
                                      </dd>

                                      {/* Stack Trace */}
                                      <dt>Stack Trace</dt>
                                      <dd className="theme-white-space">
                                        {userFailureData.stackTrace ?? "--"}
                                      </dd>

                                      {/* Inner Exception Message */}
                                      <dt>Inner Exception Message</dt>
                                      <dd className="theme-white-space">
                                        {userFailureData.innerExceptionMessage ??
                                          "--"}
                                      </dd>

                                      {/* Inner Stack Trace */}
                                      <dt>Inner Stack Trace</dt>
                                      <dd className="m-0 theme-white-space">
                                        {userFailureData.innerStackTrace ??
                                          "--"}
                                      </dd>
                                    </dl>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </>
        {/* )} */}
      </div>
    </section>
  );
}
