import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getActiveUsersState } from "../store/activeUsers";
import LoadingOverlay from "../components/LoadingOverlay";
import { dateLabels, areObjectsEqual as isInitialLoad } from "../utils";
import { getIsRecycle } from "../store/common";
import LoadingIcon from "../components/LoadingIcon";
import { LoggedInUserCountsChart } from "../service/api.dtos";

export default function ActiveUsers() {
  const { activeUserData, loading } = useSelector(getActiveUsersState);

  const isRecycle = useSelector(getIsRecycle);
  return (
    <div className="card bg-dark h-100">
      <div className="card-header h5 d-flex justify-content-between align-items-center">
        <span>Active Users</span>
        {isRecycle &&
          !isInitialLoad(activeUserData, new LoggedInUserCountsChart()) && (
            <LoadingIcon loading={loading} />
          )}
      </div>

      <div className="card-body position-relative">
        {/* Loading */}
        {(!isRecycle ||
          isInitialLoad(activeUserData, new LoggedInUserCountsChart())) &&
        loading === "pending" ? (
          <LoadingOverlay loading={loading} />
        ) : (
          <>
            <Line
              redraw={!isRecycle}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  y: {
                    // stacked: true,
                    grid: {
                      display: true,
                      color: "rgba(255,99,132,0.2)",
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: dateLabels(activeUserData.labels as string[]),
                datasets: [
                  {
                    label: "# of Active Users",
                    data: activeUserData.loginDataStatsValues,
                    fill: true,
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                  },
                ],
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
