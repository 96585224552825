import { useSelector } from "react-redux";
import LoadingOverlay from "../components/LoadingOverlay";
import { getNestingOverviewState } from "../store/nestingOverview";
import { getIsRecycle, getStartDate } from "../store/common";
import { previousDate, areObjectsEqual as isInitialLoad } from "../utils";
import Trend from "../components/Trend";
import { OverviewStatistics } from "../service/api.dtos";

export default function Overview() {
  const StartDate = useSelector(getStartDate);
  const { nestingOverviewData, loading: overviewLoading } = useSelector(
    getNestingOverviewState
  );
  const isRecycle = useSelector(getIsRecycle);

  return (
    <>
      {/* Loading */}
      {(!isRecycle ||
        isInitialLoad(nestingOverviewData, new OverviewStatistics())) &&
      overviewLoading === "pending" ? (
        <LoadingOverlay loading={overviewLoading} />
      ) : (
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 px-4">
              <div className="d-flex justify-content-between mb-3">
                <span>Previous Stats</span>
                <small className="text-muted">
                  {previousDate(StartDate ?? "")}
                </small>
              </div>

              <hr className="mx-n3"></hr>
              <dl className="row justify-content-between">
                {/* User */}

                <dt className="col-sm-5">Errors</dt>
                <dd className="col-sm-7">
                  {nestingOverviewData.previousStats?.errors}
                </dd>

                <dt className="col-sm-5">Successes</dt>
                <dd className="col-sm-7">
                  {nestingOverviewData.previousStats?.successes}
                </dd>

                <dt className="col-sm-5">Assigned</dt>
                <dd className="col-sm-7">
                  {nestingOverviewData.previousStats?.assigned}
                </dd>

                <dt className="col-sm-5">Total Completed</dt>
                <dd className="col-sm-7">
                  {nestingOverviewData.previousStats?.totalCompleted}
                </dd>

                <dt className="col-sm-5">Error Percentage</dt>
                <dd className="col-sm-7">
                  {nestingOverviewData.previousStats?.errorPercentage.toFixed(
                    2
                  )}
                </dd>
              </dl>
            </div>

            <div className="col-sm-6 px-4">
              <div className="d-flex justify-content-between mb-3">
                <span>Current Stats</span>
                <small className="text-muted">
                  {new Date(StartDate ?? "").toLocaleDateString("en-GB", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </small>
              </div>
              <hr className="mx-n3"></hr>
              <dl className="row justify-content-between">
                {/* User */}

                <dt className="col-sm-5">Errors</dt>
                <dd className="col-sm-7">
                  <Trend
                    currentStat={nestingOverviewData.currentStatsStats?.errors}
                    previousStat={nestingOverviewData.previousStats?.errors}
                  />
                </dd>

                <dt className="col-sm-5">Successes</dt>
                <dd className="col-sm-7">
                  <Trend
                    currentStat={
                      nestingOverviewData.currentStatsStats?.successes
                    }
                    previousStat={nestingOverviewData.previousStats?.successes}
                  />
                </dd>

                <dt className="col-sm-5">Assigned</dt>
                <dd className="col-sm-7">
                  <Trend
                    currentStat={
                      nestingOverviewData.currentStatsStats?.assigned
                    }
                    previousStat={nestingOverviewData.previousStats?.assigned}
                  />
                </dd>

                <dt className="col-sm-5">Total Completed</dt>
                <dd className="col-sm-7">
                  <Trend
                    currentStat={
                      nestingOverviewData.currentStatsStats?.totalCompleted
                    }
                    previousStat={
                      nestingOverviewData.previousStats?.totalCompleted
                    }
                  />
                </dd>

                <dt className="col-sm-5">Error Percentage</dt>
                <dd className="col-sm-7">
                  <Trend
                    currentStat={
                      +nestingOverviewData.currentStatsStats?.errorPercentage.toFixed(
                        2
                      )
                    }
                    previousStat={
                      +nestingOverviewData.previousStats?.errorPercentage.toFixed(
                        2
                      )
                    }
                  />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
