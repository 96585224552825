import { setConfirmModal } from "../store/commonSlice";
import { useAppDispatch } from "./reduxTypedHooks";

function useConfirmIntercept() {
  const dispatch = useAppDispatch();

  //Must be ClickEvent not onChangeEvent
  const click = ({
    event,
    message,
    callback,
  }: {
    event?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLInputElement, MouseEvent>;

    message?: string;
    callback?: Function;
  }) => {
    event?.preventDefault && event.preventDefault();
    dispatch(
      setConfirmModal({
        visibility: true,
        message: message ? message : "Are you sure?",
        callback: callback,
      })
    );
    return false;
  };

  return click;
}
export default useConfirmIntercept;
