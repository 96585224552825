export function useAppType() {
  type AppType =
    | "Dev"
    | "Staging"
    | "Live"
    | "Docker"
    | "Unknown"
    | "Test"
    | "";

  let appType: AppType = "";

  switch (process.env.REACT_APP_BUILD_TYPE) {
    case "development":
      appType = "Dev";
      break;
    case "staging":
      appType = "Staging";
      break;
    case "production":
      appType = "Live";
      break;
    case "docker":
      appType = "Docker";
      break;
    case "testing":
      appType = "Test";
      break;

    default:
      appType = "Unknown";
      break;
  }

  return appType;
}
