import React, { FunctionComponent } from "react";

const DisplayCard: FunctionComponent<{
  title: string;
}> = (props) => {
  return (
    <div className="theme-display-card p-3 bg-secondary rounded text-center d-flex flex-column flex-grow-1 justify-content-center">
      <div>
        <h6 className="theme-display-card-title">{props.title}</h6>
        <p className="display-6 mb-0">{props.children}</p>
      </div>
    </div>
  );
};
export default DisplayCard;
