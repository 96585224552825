import { VoidFunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { Loading } from "../store/commonTypes";

type Props = { loading: Loading; className?: string };

const LoadingIcon: VoidFunctionComponent<Props> = ({ loading, className }) => {
  return (
    <>
      {loading === "pending" && (
        <FontAwesomeIcon
          icon={faSync}
          fixedWidth
          spin
          className={`text-primary opacity-50 ${className}`}
        />
      )}
      {loading === "failed" && (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          fixedWidth
          title="Request failed"
          className={`text-warning ${className}`}
        />
      )}
    </>
  );
};

export default LoadingIcon;
