import { Loading } from "./commonTypes";
import { RootState } from "../store/store";
import { UserBasics } from "../service/api.dtos";

export const getAllUsers = (state: RootState): Array<UserBasics> =>
  state.usersState.allUsers;
export const getALLUsersLoading = (state: RootState): Loading =>
  state.usersState.allUsersLoading;
export const getExportUsersLoading = (state: RootState): Loading =>
  state.usersState.exportUsersLoading;
