export function dateFormatter(date: string | undefined) {
  return date ? (
    new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  ) : (
    <div className="opacity-50 text-center">--</div>
  );
}
